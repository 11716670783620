/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

export const CLIENT_SIDE_PREFIX = __ROLE_PATH__;

const RolePrefixes = Object.freeze({
    ORDERING: '/ordering/',
    PUBLIC_ACCESS: '/public/',
    WORKGROUP: '/group/',
    APPROVAL: '/approval/',
    PRODUCTION: '/psm/',
    INVOICING: '/invoicing/',
    SITE: '/site/',
    REVIEW: '/review/',
    AUTOSTOCK: '/fgim/',
    ADMINISTRATOR: '/App-Role/',
});

export default RolePrefixes;

export const isCurrentRole = (prefix) => {
    return CLIENT_SIDE_PREFIX === prefix;
};