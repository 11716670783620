/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

import SmartImage from '~components/images/SmartImage';
import usePathName from '~components/routing/usePathName';
import useRedirect from '~components/routing/useRedirect';

import ContainerWithAction from '../buttons/ContainerWithAction';
import { rootAutomationIDPropTypes } from '../id/RootAutomationID';
import LocalizedParameter from '../text/rsaMessageFormat/LocalizedParameter';

import HeaderSearch from './HeaderSearch';
import MobileMenu from './MobileMenu';
import HeaderToolbar from './toolbar/HeaderToolbar';

const MiddleHeader = forwardRef(({ rootID, searchOptions, toolbarOptions, mobileMenuOptions, isLocked }, ref) => {

    const redirect = useRedirect();
    const [mobileMenuShown, setMobileMenuShown] = useState(false);
    const hasMobileMenu = !!mobileMenuOptions;
    const pathName = usePathName();

    useEffect(() => {
        // When we change pages, close the menu
        setMobileMenuShown(false);
    }, [pathName]);

    const logoOnClick = !isLocked && redirect.home().redirect || undefined;

    const appNameParameter = new LocalizedParameter('COMMON_UI.TITLE.APPLICATION_TITLE');

    return (
        <div ref={ref} className="topbar">
            <div className="site-branding">
                <ContainerWithAction className="site-logo" action={logoOnClick}>
                    <SmartImage
                        name="logo"
                        localeKey="COMMON_UI.IMGALT.LOGO"
                        parameters={{
                            APPLICATION_NAME: appNameParameter,
                        }} />
                </ContainerWithAction>
                <ContainerWithAction className="site-logo-mobile" action={logoOnClick}>
                    <SmartImage name="logoSmall" localeKey="COMMON_UI.IMGALT.LOGO" />
                </ContainerWithAction>
            </div>

            {searchOptions &&
            <HeaderSearch
                rootID={rootID}
                hiddenOnMobile
                {...searchOptions}
            />}
            {toolbarOptions && (
                <HeaderToolbar
                    rootID={rootID}
                    mobileMenuShown={hasMobileMenu && mobileMenuShown}
                    setMobileMenuShown={hasMobileMenu && setMobileMenuShown}
                    {...toolbarOptions}
                />
            )}
            {mobileMenuOptions && <MobileMenu shown={mobileMenuShown} {...mobileMenuOptions} />}
        </div>
    );
});

MiddleHeader.displayName = 'MiddleHeader';

MiddleHeader.propTypes = {
    rootID: rootAutomationIDPropTypes,
    searchOptions: PropTypes.object,
    toolbarOptions: PropTypes.object,
    mobileMenuOptions: PropTypes.object,
    isLocked: PropTypes.bool,
};

export default MiddleHeader;
