/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * Underline tags
 * {u}Text to be underlined{/u}
 */
export default new SimpleTag(
    'u',
    (key, contents) => {
        return <u key={key}>{contents}</u>;
    },
    (contents) => contents
);
