/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { Modal as BModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import preventDefaultAndStopPropagation from '~/utils/events/preventDefaultAndStopPropagation';
import FocusTrap from '~components/a11y/FocusTrap';
import { FeatherIconButton } from '~components/images/icons/FeatherIcon';
import LocalizedString from '~components/text/LocalizedString';
import { portalRoot } from '~components/utils/createLabeledPortal';
import { useHideScrollbar } from '~components/utils/ScrollBarHider';
import withZIndex from '~components/utils/withZIndex';

import { automationIDPropTypes, getExtraAutomationID } from '../id/AutomationID';

const ModalClose = ({ automationID, toggle }) => {
    if (!toggle) {
        return null;
    }
    return (
        <FeatherIconButton
            automationID={automationID && getExtraAutomationID(automationID, 'closeButton')}
            name="x"
            inline
            tooltip={<LocalizedString localeKey="COMMON_UI.IMGALT.CLOSE_MODAL" />}
            action={toggle}
        />
    );
};

ModalClose.propTypes = {
    automationID: automationIDPropTypes,
    toggle: PropTypes.func,
};

BModal.prototype.handleTab = () => {/* do nothing - ReactStrap's tab handling breaks our FocusTraps */};
BModal.openCount = 1; // Pretend we start with 1 open to stop ReactStrap from applying its faulty overflow logic (useHideScrollbar does what we want instead)

export const FOOTER_CLASS = Object.freeze({
    CENTER: 'modal-footer--center',
    LEFT_CENTER_RIGHT: 'modal-footer--left-center-right',
});

const Modal = ({ automationID, isOpen, toggle, closeOnBGClick, onOpened, onClosed, title, footer, footerClass, className, size, children, zIndex }) => {
    useHideScrollbar(isOpen);
    return (
        <BModal
            isOpen={isOpen}
            toggle={toggle}
            backdrop={closeOnBGClick ? true : 'static'}
            onOpened={onOpened}
            onClosed={onClosed}
            className={className}
            size={size}
            zIndex={zIndex}
            centered
            container={portalRoot}
        >
            <FocusTrap>
                <ModalHeader aria-live="assertive" tag="div" close={<ModalClose automationID={automationID} toggle={toggle} />}>
                    {title}
                </ModalHeader>
                <form onSubmit={preventDefaultAndStopPropagation}>
                    <ModalBody>{children}</ModalBody>
                    {footer && <ModalFooter className={footerClass}>{footer}</ModalFooter>}
                </form>
            </FocusTrap>
        </BModal>
    );
};

Modal.propTypes = {
    automationID: automationIDPropTypes,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    closeOnBGClick: PropTypes.bool,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    footer: PropTypes.node,
    footerClass: PropTypes.oneOf(Object.values(FOOTER_CLASS)),
    className: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    zIndex: PropTypes.number.isRequired,
};

Modal.defaultProps = {
    footerClass: FOOTER_CLASS.CENTER,
};

const ModalWithZIndex = withZIndex(Modal);

const ModalContainer = ({ isOpen, ...modalProps }) => {
    if (!isOpen) {
        return null;
    }
    return <ModalWithZIndex isOpen {...modalProps} />;
};

ModalContainer.propTypes = {
    isOpen: PropTypes.bool,
};

export default ModalContainer;
