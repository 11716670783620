/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

const listItem = new SimpleTag(
    'li',
    (key, contents) => {
        return <li key={key}>{contents}</li>;
    },
    (contents) => `${contents}`
);

export const renderListItemsAsText = ({ contents, renderContents }) => {
    return contents
        .filter((content) => typeof content !== 'string')
        .map((content) => listItem.renderAsText({ parsedTag: content, renderContents }))
        .join('\n');
};

export const renderListItems = ({ contents, keyGen, renderContents }) => {
    return contents
        .filter((content) => typeof content !== 'string')
        .map((content) => listItem.render({ parsedTag: content, keyGen, renderContents }));
};

export const isValidList = (contents) => {
    if (contents.length === 0) {
        return false;
    }
    return contents.every((content) => {
        if (typeof content === 'string') {
            return content.trim() === '';
        }
        return content.upperTagName === 'LI';
    });
};
