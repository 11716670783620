/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import UntitledPage from '~components/page/UntitledPage';
import Spinner from '~components/skeleton/Spinner';

import usePathName from '../usePathName';

const LOADER_DELAY_MS = 250;

const PageLoader = ({ isFullPage }) => {
    // This is here so we reset the spinner hide/show when navigating between pages
    const pathName = usePathName();

    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(false);
        const timeout = setTimeout(() => setShow(true), LOADER_DELAY_MS);
        return () => {
            clearTimeout(timeout);
        };
    }, [setShow, pathName]);

    let spinnerClass = 'page-loader';
    if (show) {
        spinnerClass += ' show';
    }
    const spinner = (
        <div className={spinnerClass}>
            <Spinner size={'100%'} />
        </div>
    );
    if (isFullPage) {
        return (
            <UntitledPage>
                <div className="container page-loader-container">{spinner}</div>
            </UntitledPage>
        );
    }
    return <div className="page-loader-container">{spinner}</div>;
};

PageLoader.propTypes = {
    isFullPage: PropTypes.bool,
};
export default PageLoader;
