/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { isValidElement } from 'react';

import LocalizedString from '../text/LocalizedString';

import { formattedStringParamtersPropType } from './rsaMessageFormat/propTypes';

export const localizedStringOrNodePropTypes = PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({
        key: PropTypes.string.isRequired,
        parameters: formattedStringParamtersPropType,
    }),
]);

const localizedStringOrNode = (value) => {
    if (isValidElement(value) || typeof value === 'string') {
        return value;
    }
    return <LocalizedString localeKey={value.key} parameters={value.parameters} />;
};

export default localizedStringOrNode;
