/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

const path = 'distributionLists';

// Delete an existing distribution list
export const deleteList = async (listID) => {
    return api.delete(`${path}/${listID}`);
};

// Get all distribution lists
export const getAllLists = async () => {
    return api.get(path);
};

// Create a new distribution list
export const createList = async (nameObj) => {
    return api.put(path, nameObj);
};

// Add an entry to a list
export const addListEntry = async (listID, address) => {
    return api.patch(`${path}/${listID}`, address);
};

// Remove an entry from a list
export const removeListEntry = async (listID, entryID) => {
    return api.delete(`${path}/${listID}/${entryID}`);
};

// Update the quantity for an entry
export const updateQuantity = async (listID, entryID, quantity) => {
    return api.patch(`${path}/${listID}/${entryID}`, quantity);
};

// Search a list for entries
export const searchList = async (listID, page = 0, limit, search, includeGlobal) => {
    // url, page, optional params, limit
    return api.getList(`${path}/${listID}/searchList`, page, { search, includeGlobal }, limit);
};

// Search all shipping addresses for entries
export const searchAll = async (listID, page = 0, limit, search, includeGlobal) => {
    return api.getList(`${path}/${listID}/searchAll`, page, { search, includeGlobal }, limit);
};