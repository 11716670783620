/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '~/components/alerts/Alert';
import TimeoutModal from '~/roles/ordering/header/TimeoutModal';
import { getSystemMessage } from '~common-store/slices/systemMessage';
import { setScrollMarginTop } from '~components/a11y/useScrollIntoView';
import useCallbackRef from '~utils/hooks/useCallbackRef';

import { AlertType } from '../alerts/AlertType';
import LocalizedString from '../text/LocalizedString';

import BottomHeader from './BottomHeader';
import MiddleHeader from './MiddleHeader';
import TopHeader from './TopHeader';

const Header = ({ topHeaderOptions, middleHeaderOptions, bottomHeaderOptions }) => {
    const [isStuck, setIsStuck] = useState(false);
    const [reservedHeight, setReservedHeight] = useState(0);
    // We use a callback for the ref instead of useRef because we need Header to re-render when the ref updates
    const [middleHeader, middleHeaderRef] = useCallbackRef(null);
    const bottomHeaderRef = useRef(null);
    const dispatch = useDispatch();
    dispatch(getSystemMessage());
    const showSystemMessage = useSelector((state) => state.systemMessage.showSystemMessage);

    useEffect(() => {
        if (!middleHeader) {
            // Middle header ref isn't initialized yet, nothing to set up or clean up
            return undefined;
        }
        const observer = new IntersectionObserver(
            ([e]) => {
                if (e.isIntersecting) {
                    setIsStuck(false);
                    setScrollMarginTop(0);
                } else {
                    setIsStuck(true);
                    const floatingHeaderHeight = bottomHeaderRef.current.offsetHeight;
                    setReservedHeight(floatingHeaderHeight);
                    setScrollMarginTop(floatingHeaderHeight);
                }
            },
            { threshold: [0, 1] }
        );
        observer.observe(middleHeader);
        return () => {
            observer.unobserve(middleHeader);
        };
    }, [middleHeader, bottomHeaderRef, setIsStuck]);
    return (
        <header className={`site-header ${isStuck ? 'navbar-stuck' : ''}`}>
            <TopHeader {...topHeaderOptions} />
            <MiddleHeader ref={middleHeaderRef} {...middleHeaderOptions} />
            {isStuck && <div style={{ height: reservedHeight }} />}
            <BottomHeader ref={bottomHeaderRef} {...bottomHeaderOptions} />
            {showSystemMessage && (
                <Alert type={AlertType.USER_ERROR}>
                    <LocalizedString localeKey="COMMON_UI.ERROR.SYSTEMALERT" />
                </Alert>
            )}
            <TimeoutModal />
        </header>
    );
};

Header.propTypes = {
    topHeaderOptions: PropTypes.object.isRequired,
    middleHeaderOptions: PropTypes.object.isRequired,
    bottomHeaderOptions: PropTypes.object.isRequired,
};

export default Header;
