/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { Fragment } from 'react';

import { joinFragments } from './fragmentJoiner';
import { parse } from './lexer';
import { tagMap } from './tagList';
import * as tokenParameterRenderer from './tags/tokenParameterRenderer';

const arrayToObject = (arrayOrObject) => {
    if (Array.isArray(arrayOrObject)) {
        return arrayOrObject.reduce((acc, value, index) => {
            return {
                ...acc,
                ['' + index]: value,
            };
        }, {});
    } else {
        return arrayOrObject;
    }
};

class KeyGen {
    constructor() {
        this._lastKey = 0;
    }

    get next() {
        return this._lastKey++;
    }
}

const toReact = (parsedInput, parameters, keyGen) => {
    if (parsedInput === null || parsedInput.length === 0) {
        return <Fragment key={keyGen.next} />;
    }
    if (typeof parsedInput === 'string') {
        return <Fragment key={keyGen.next}>{parsedInput}</Fragment>;
    }
    const renderContents = (contents) => toReact(contents, parameters, keyGen);
    if (Array.isArray(parsedInput)) {
        return joinFragments(<Fragment key={keyGen.next}>{parsedInput.map(renderContents)}</Fragment>);
    }

    const tagRenderer = tagMap.get(parsedInput.upperTagName);
    if (tagRenderer) {
        return tagRenderer.render({ parsedTag: parsedInput, keyGen, renderContents });
    }
    return tokenParameterRenderer.render({
        parsedTag: parsedInput,
        keyGen,
        parameters,
        renderContents,
    });
};

// eslint thinks this function is a React component, but it isn't (though it is very close, so I don't blame eslint)
// eslint-disable-next-line react/display-name
export default (input, parameters) => {
    const parsedInput = parse(input);
    if (!parsedInput || parsedInput.length === 0) {
        // Zero-width space, to make sure we always take up the right vertical space even if the string is empty
        return <>{'\u200b'}</>;
    }
    return toReact(parsedInput, arrayToObject(parameters), new KeyGen());
};
