/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useCallback, useState } from 'react';

const useOnOffToggle = (onByDefault) => {
    const [isOn, setIsOn] = useState(onByDefault);

    const turnOn = useCallback(() => {
        setIsOn(true);
    }, [setIsOn]);

    const turnOff = useCallback(() => {
        setIsOn(false);
    }, [setIsOn]);

    const toggle = useCallback(() => {
        setIsOn((v) => !v);
    }, [setIsOn]);

    return [isOn, turnOn, turnOff, toggle];
};

export default useOnOffToggle;
