/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import localizedStringOrNode, { localizedStringOrNodePropTypes } from '../text/LocalizedStringOrNode';

const FormHelp = ({ helpID, help }) => {
    if (!help) {
        return null;
    }
    return (
        <div className="form-text" id={helpID}>
            {localizedStringOrNode(help)}
        </div>
    );
};

FormHelp.propTypes = {
    helpID: PropTypes.string,
    help: localizedStringOrNodePropTypes,
};

export default FormHelp;
