/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useCallback } from 'react';

import { fixURL } from '~instance';

const LAUNCHER_URL = fixURL('/webhelp/launcher');

let helpSandbox;
const showHelp = (helpKey) => {
    if (helpSandbox) {
        document.body.removeChild(helpSandbox);
    }
    helpSandbox = document.createElement('iframe');
    helpSandbox.style.display = 'none';
    helpSandbox.src = `${LAUNCHER_URL}?key=${encodeURIComponent(helpKey)}`;
    document.body.appendChild(helpSandbox);
};

export default (helpKey) => {
    return useCallback(
        () => {
            showHelp(helpKey);
        },
        [helpKey]
    );
};
