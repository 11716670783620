/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { Component } from 'react';

import getEnhancedComponentName from '~components/getEnhancedComponentName';

import ZIndex from './ZIndex';

const withZIndex = (WrappedComponent) => {
    class EnhancedComponent extends Component {
        constructor(props) {
            super(props);
            this.ZIndex = new ZIndex();
            this.state = { zIndex: -1 };
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    zIndex={this.state.zIndex}
                />
            );
        }

        componentDidMount() {
            const zIndex = this.ZIndex.initialize();
            this.setState((state) => {
                return { ...state, zIndex };
            });
        }

        componentWillUnmount() {
            this.ZIndex.clear();
        }
    }

    EnhancedComponent.displayName = getEnhancedComponentName('WithZIndex', WrappedComponent);

    return EnhancedComponent;
};

export default withZIndex;