/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { fixURL } from '~instance';

import { ANYTHING_BUT_CURLY_AND_QUOTE as validAttributePattern } from './attributePatterns';
import SingleAttributeTag from './singleAttributeTag';

/**
 * Turns text into a link to a given URL, opening the URL in a new browser window.
 * {popup=/path/for/link}Link text{/popup}
 *
 * See also link
 */
export default new SingleAttributeTag(
    'popup',
    validAttributePattern,
    (key, attributeValue, contents) => {
        return (
            <a key={key} href={fixURL(attributeValue)} target="_blank" rel="noreferrer">
                {contents}
            </a>
        );
    },
    (_attributeValue /* unused in text formatting */, contents) => contents
);
