/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import Heading from '~components/text/Heading';
import patternPropType from '~utils/prop-types/pattern';

const BaseWidget = ({ title, children, light, type, level = 2 }) => {
    const classes = ['widget'];
    if (light) {
        classes.push('widget-light-skin');
    }
    if (type) {
        classes.push(`widget-${type}`);
    }
    return (
        <section className={classes.join(' ')}>
            {title && (
                <Heading level={level} className="widget-title">
                    {title}
                </Heading>
            )}
            {children}
        </section>
    );
};

BaseWidget.propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    light: PropTypes.bool,
    type: patternPropType(/[a-z]+(-[a-z]+)*/),
    level: PropTypes.number,
};

export default BaseWidget;
