/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { get as fetchPrivacyPolicy } from '~api/common/settings/privacyPolicy.js';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';
import deepFreeze from '~utils/objects/deepFreeze';


export const getPrivacyPolicy = createSimpleAPIAsyncThunk('privacyPolicy/getPrivacyPolicy', fetchPrivacyPolicy);


const initialState = deepFreeze({
    text: '',
});

const privacyPolicySlice = createSlice({
    name: 'privacyPolicy',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getPrivacyPolicy.fulfilled, (state, action) => {
            state.text = action.payload.text;
        });
    },
});


export default privacyPolicySlice.reducer;
