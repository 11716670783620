/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { fixURL } from '~instance';
import { getLogger } from '~utils/logging';

import APIError from './APIError';
import { decreasePendingUpdates, increasePendingUpdates } from './updateStatus';

const logger = getLogger(Symbol('API'));

const API_KEY_HEADER = 'W-apiKey';
const API_KEY_URL = 'api/key';
const IS_POLLING_HEADER = 'W-isPolling';

const RESPONSE_API_KEY_KEY = 'apiKey';
const RESPONSE_REQUEST_ID_KEY = 'requestID';
const RESPONSE_STATUS_KEY = 'status';
const RESPONSE_MESSAGE_KEY = 'message';
const RESPONSE_DATA_KEY = 'data';

const STATUS_CODES = Object.freeze({
    SUCCESS_MIN: 200,
    SUCCESS_MAX: 299,
    UNAUTHORIZED: 401,
});

let apiKey = null;

let apiUpdateRequest = null;

const updateAPIKey = async () => {
    logger.info('Updated API Key was requested');
    if (apiUpdateRequest === null) {
        apiUpdateRequest = (async () => {
            try {
                await sendPollRequest(API_KEY_URL, null /* parameters */, { isAPIKeyRequest: true });

            } catch (error) {
                logger.error(`Error refreshing APIKey: ${error.message}`);
                if (error instanceof APIError) {
                    throw error;
                }
                throw new APIError(
                    APIError.TYPE.UNEXPECTED_CLIENT_ERROR,
                    null /* requestID */,
                    'Unable to update API Key',
                    { cause: error }
                );
            } finally {
                apiUpdateRequest = null;
            }
        })();
    }
    await apiUpdateRequest;
};

const addRequestData = (method, fetchURL, fetchOptions, requestData, isMultiPart) => {
    if (requestData) {
        if (method === 'GET') {
            return addRequestDataForGET(fetchURL, fetchOptions.headers, requestData);
        } else if (isMultiPart) {
            fetchOptions.body = buildMultipartFormData(requestData);
        } else {
            fetchOptions.body = JSON.stringify(requestData);
            fetchOptions.headers['Content-Type'] = 'application/json';
        }
    }
    return fetchURL;
};

const addRequestDataForGET = (fetchURL, headers, requestData) => {
    let hasQueryParameters = false;
    const queryParameters = new URLSearchParams();
    for (const [parameterName, parameterValue] of Object.entries(requestData)) {
        if (parameterName.startsWith('W-')) {
            headers[parameterName] = parameterValue;
        } else {
            hasQueryParameters = true;
            if (Array.isArray(parameterValue)) {
                parameterValue.forEach((value) => {
                    queryParameters.append(parameterName, value);
                });
            } else if (parameterValue !== null && parameterValue !== undefined) {
                queryParameters.append(parameterName, parameterValue);
            }
        }
    }
    if (hasQueryParameters) {
        return `${fetchURL}?${queryParameters.toString()}`;
    } else {
        return fetchURL;
    }
};

const buildMultipartFormData = (requestData) => {
    const parameters = new FormData();
    for (const [parameterName, parameterValue] of Object.entries(requestData)) {
        if (Array.isArray(parameterValue) || parameterValue instanceof FileList) {
            for (const value of parameterValue) {
                parameters.append(parameterName, value);
            }
        } else if (parameterValue !== null && parameterValue !== undefined) {
            parameters.append(parameterName, parameterValue);
        }
    }
    return parameters;
};

let nextClientRequestID = 1;

export const getNextClientRequestID = () => nextClientRequestID;

const getAPIKey = async () => {
    if (!apiKey) {
        await updateAPIKey();
    }
    return apiKey;
};

const assertJSONResponse = (response, logPrefix, apiURL) => {
    const responseContentType = response.headers.get('Content-Type') || '';
    if (responseContentType !== 'application/json' && !responseContentType.startsWith('application/json;')) {
        logger.error(`${logPrefix} Unexpected contentType[${responseContentType}] seen in response to ${apiURL}`);
        throw new APIError(
            APIError.TYPE.UNEXPECTED_RESPONSE,
            null /* requestID */,
            'API did not respond with JSON - server is probably down'
        );
    }
};

const assertSuccess = (requestID, status, responseJSON, logPrefix, apiURL) => {
    if (status < STATUS_CODES.SUCCESS_MIN || status > STATUS_CODES.SUCCESS_MAX) {
        logger.error(`${logPrefix} ${apiURL} Request failure`, responseJSON);
        throw new APIError(APIError.TYPE.fromStatusCode(status), requestID, 'Invalid API Request');
    }
};

const updateAPIKeyFromResponse = (responseJSON) => {
    // The apiKey is in every response from the server, pull it out and store it on client-side
    const responseAPIKey = responseJSON[RESPONSE_API_KEY_KEY];
    if (responseAPIKey) {
        apiKey = responseAPIKey;
    }
};

const isInvalidAPIKeyResponse = (status, responseJSON) => {
    const message = responseJSON[RESPONSE_MESSAGE_KEY];
    return status === STATUS_CODES.UNAUTHORIZED && message === 'Invalid API key.';
};

const sendAPIRequest = async (method, apiURL, requestData, { isAPIKeyRequest, isMultiPart } = {}) => {
    const clientRequestID = nextClientRequestID++;
    const logPrefix = `sendAPIRequest[${clientRequestID}]`;
    if (logger.isDebugEnabled()) {
        logger.traceOrDebug(`${logPrefix} called method[${method}] apiURL[${apiURL}]`, requestData);
    }

    const headers = {};

    if (!isAPIKeyRequest) {
        // Prevents an infinite loop - if this request is to get the key, we don't want to get the key again
        // This is just getting the key so our request is authenticated
        headers[API_KEY_HEADER] = await getAPIKey();
    }

    const fetchOptions = {
        method: method,
        headers: headers,
    };
    if (method !== 'GET') {
        increasePendingUpdates();
    }
    let success = false;
    try {
        const fetchURL = addRequestData(method, fixURL(apiURL), fetchOptions, requestData, isMultiPart);

        logger.info(`${logPrefix} Fetching ${fetchURL}`);
        const response = await fetch(fetchURL, fetchOptions);

        assertJSONResponse(response, logPrefix, apiURL);

        const responseJSON = await response.json();

        const requestID = responseJSON[RESPONSE_REQUEST_ID_KEY];
        const status = responseJSON[RESPONSE_STATUS_KEY];

        logger.debug(`${logPrefix} RequestID[${requestID}] status[${status}]`, responseJSON);

        if (!isAPIKeyRequest && isInvalidAPIKeyResponse(status, responseJSON)) {
            logger.warn(`${logPrefix} Request failed due to outdated API Key - refreshing API Key before trying again`);
            await updateAPIKey();
            return sendAPIRequest(method, apiURL, requestData);
        }

        assertSuccess(requestID, status, responseJSON, logPrefix, apiURL);

        updateAPIKeyFromResponse(responseJSON);

        logger.info(`${logPrefix} Request success`);
        success = true;

        return responseJSON[RESPONSE_DATA_KEY];

    } finally {
        if (method !== 'GET') {
            decreasePendingUpdates(success);
        }
    }
};

const sendGETRequest = async (apiURL, parameters, opt_isAPIKeyRequest) => {
    return sendAPIRequest('GET', apiURL, parameters, { isAPIKeyRequest: opt_isAPIKeyRequest });
};

const sendPollRequest = async (apiURL, parameters, opt_isAPIKeyRequest) => {
    return sendGETRequest(apiURL, { [IS_POLLING_HEADER]: 'true', ...parameters }, opt_isAPIKeyRequest);
};

const sendGETListRequest = async (apiURL, page, opt_searchParameters, opt_limit) => {
    const parameters = opt_searchParameters || {};
    parameters['page'] = page;
    if (opt_limit) {
        parameters['limit'] = opt_limit;
    }
    return sendGETRequest(apiURL, parameters);
};

const getEntireList = async (apiURL, opt_searchParameters) => {
    logger.debug('getEntireList');
    let fullList = [];
    let hasMore = true;
    let page = 0;
    while (hasMore) {
        const data = await sendGETListRequest(apiURL, page, opt_searchParameters);
        const total = data['total'];
        const rPage = data['page'];
        const limit = data['limit'];
        hasMore = total > limit * (rPage + 1);
        page++;
        fullList = fullList.concat(data['results']);
    }
    return fullList;
};

const sendPOSTRequest = async (apiURL, parameters, options) => {
    return sendAPIRequest('POST', apiURL, parameters, options);
};

const sendPUTRequest = async (apiURL, parameters) => {
    return sendAPIRequest('PUT', apiURL, parameters);
};

const sendPATCHRequest = async (apiURL, parameters) => {
    return sendAPIRequest('PATCH', apiURL, parameters);
};

const sendDELETERequest = async (apiURL, parameters) => {
    return sendAPIRequest('DELETE', apiURL, parameters);
};

const withPrefix = (api) => (prefix) => {
    const withFunctionPrefix = (fn) => {
        return async (apiURL, ...rest) => {
            return fn(`${prefix}/${apiURL}`, ...rest);
        };
    };
    const newAPI = {
        get: withFunctionPrefix(api.get),
        getList: withFunctionPrefix(api.getList),
        getEntireList: withFunctionPrefix(api.getEntireList),
        poll: withFunctionPrefix(api.poll),
        post: withFunctionPrefix(api.post),
        put: withFunctionPrefix(api.put),
        patch: withFunctionPrefix(api.patch),
        delete: withFunctionPrefix(api.delete),
    };
    newAPI.withPrefix = withPrefix(newAPI);
    return Object.freeze(newAPI);
};

export default withPrefix({
    get: sendGETRequest,
    getList: sendGETListRequest,
    getEntireList: getEntireList,
    poll: sendPollRequest,
    post: sendPOSTRequest,
    put: sendPUTRequest,
    patch: sendPATCHRequest,
    delete: sendDELETERequest,
})('api');
