/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import Row from '~components/layout/grid/Row';
import useLocalizations from '~components/text/useLocalizations';

import FooterCustomColumn from './FooterCustomColumn';

const BREAK_4 = Object.freeze({ md: 6, lg: 3 });
const BREAK_3 = Object.freeze({ lg: 4 });
const BREAK_2 = Object.freeze({ md: 6 });
const BREAK_1 = Object.freeze({});

const getColumnBreakpoints = (numColumns) => {
    switch (numColumns) {
        case 4:
            return BREAK_4;
        case 3:
            return BREAK_3;
        case 2:
            return BREAK_2;
        default:
            return BREAK_1;
    }
};

const COLUMNS = [1, 2, 3, 4].map((column) => ({
    headerKey: `COMMON_UI.LABEL.FOOTER_HEADER${column}`,
    contentKey: `COMMON_UI.LABEL.FOOTER_CONTENT${column}`,
}));

const LOCALE_KEYS = COLUMNS.reduce((accumulator, value) => {
    accumulator.push(value.headerKey);
    accumulator.push(value.contentKey);
    return accumulator;
}, []);

const FooterCustomColumns = () => {
    const localizedValues = useLocalizations(LOCALE_KEYS);

    const columns = COLUMNS.reduce((newColumns, column) => {
        const header = localizedValues.shift();
        const content = localizedValues.shift();
        if (header || content) {
            newColumns.push({
                headerKey: column.headerKey,
                header,
                contentKey: column.contentKey,
                content,
            });
        }
        return newColumns;
    }, []);

    const columnBreakpoints = getColumnBreakpoints(columns.length);

    return (
        <Row>
            {columns.map((column, i) => (
                <FooterCustomColumn
                    key={i}
                    columnBreakpoints={columnBreakpoints}
                    headerKey={column.headerKey}
                    header={column.header}
                    contentKey={column.contentKey}
                    content={column.content}
                />
            ))}
        </Row>
    );
};

export default FooterCustomColumns;
