/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useEffect } from 'react';


let hiddenDepth = 0;

const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
document.documentElement.style.setProperty('--scrollbar-width', `${scrollWidth}px`);

const hide = () => {
    hiddenDepth++;
    if (hiddenDepth === 1) {
        document.body.classList.add('scroll-disabled');
    }
};

const show = () => {
    hiddenDepth--;
    if (hiddenDepth === 0) {
        document.body.classList.remove('scroll-disabled');
    }
};

export const useHideScrollbar = (open) => {
    useEffect(() => {
        if (!open) {
            return undefined;
        }
        hide();
        return show;
    });
};