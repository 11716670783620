/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

/**
 * An event callback that does nothing except call preventDefault.
 * For use in where we don't want the browser's default behavior, but also don't want any additional custom behavior
 * for the DOM event.
 * If additional custom behavior is desired, wrap it in useCallback and simply call stopPropagation and preventDefault directly.
 *
 * @param {Event} e
 */
export default (e) => {
    e.preventDefault();
    e.stopPropagation();
};