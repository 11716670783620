/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { get as fetchShipDateOptions } from '~api/ordering/shipDateOptions';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';

export const getShipDateOptions = createSimpleAPIAsyncThunk('shipDateOptions/get', fetchShipDateOptions);

const shipDateOptionsSlice = createSlice({
    name: 'shipDateOptions',
    initialState: {},
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getShipDateOptions.fulfilled, (state, action) => {
            const orderID = action.meta.arg;
            const shipDateOptions = action.payload;
            state[orderID] = shipDateOptions;
        });
    },
});

export default shipDateOptionsSlice.reducer;
