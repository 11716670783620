/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef, useCallback } from 'react';

import Button from '~components/buttons/Button';

const ConfirmModalButton = forwardRef(({ id, toggleModal, action, label, disabled, type = 'primary' }, ref) => {
    const confirmAndClose = useCallback(() => {
        if (action() !== false) {
            toggleModal && toggleModal();
        }
    }, [toggleModal, action]);

    // The button is disabled if either it was called out as disabled, or action was not provided
    const buttonAction = disabled || !action ? undefined : confirmAndClose;
    return (
        <Button id={id} type={type} action={buttonAction} ref={ref}>
            {label}
        </Button>
    );
});

ConfirmModalButton.displayName = 'ConfirmModalButton';

ConfirmModalButton.propTypes = {
    id: PropTypes.string,
    toggleModal: PropTypes.func,
    action: PropTypes.func,
    label: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.string,
};

export default ConfirmModalButton;
