/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { getAllLists } from '~api/common/settings/distributionLists';

import createSimpleAPIAsyncThunk from '../helpers/createSimpleAPIAsyncThunk';

export const getDistributionLists = createSimpleAPIAsyncThunk('distributionLists/getAll', getAllLists);

const initialState = {
    lists: null,
};

const distributionListsSlice = createSlice({
    name: 'distributionLists',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        // Fetch distribution lists when the page is reloaded or after a list is created/deleted
        builder.addCase(getDistributionLists.fulfilled, (state, action) => {
            state.lists = action.payload;
        });
    },
});

export default distributionListsSlice.reducer;