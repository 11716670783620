/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { get as fetchRegistrationFields } from '~/api/publicAccess/registrationFields';
import { get as fetchLDAPAccountValues } from '~api/publicAccess/ldapAccountCreation';
import { get as fetchLogin } from '~api/publicAccess/login';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';

const initialState = {
    panels: null,
    ldapLoginName: '',
    registrationFields: {},
};

export const getLogin = createSimpleAPIAsyncThunk('login/get', fetchLogin);
export const getLDAPLoginName = createSimpleAPIAsyncThunk('login/getLDAPLoginName', fetchLDAPAccountValues);
export const getRegistrationFields = createSimpleAPIAsyncThunk('login/getRegistrationFields', fetchRegistrationFields);

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getLogin.fulfilled, (state, action) => {
            state.panels = action.payload.panels;
        });

        builder.addCase(getLDAPLoginName.fulfilled, (state, action) => {
            state.ldapLoginName = action.payload.ldapLoginName;
        });

        builder.addCase(getRegistrationFields.fulfilled, (state, action) => {
            state.registrationFields = action.payload;
        });
    },
});

export default loginSlice.reducer;
