/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useSetBrowserTitleLocaleKey, useSetRouteHelpKey } from '~components/header/help/HelpContext';
import { getLogger } from '~utils/logging';

import ErrorPage from '../errorPages/ErrorPage';
import ServerBusyErrorPage from '../errorPages/ServerBusyErrorPage';
import RouteErrorBoundary from '../RouteErrorBoundary';

import PageLoader from './PageLoader';

const logger = getLogger(Symbol('Components:Routing:LazyRouteContents'));

const LAZY_LOAD_TIMEOUT_MS = 30_000;

const useLazyLoader = ({ path, lazyLoader }) => {
    const [lazyModule, setLazyModule] = useState(null);

    useEffect(() => {
        if (!lazyLoader) {
            // No lazy-loader, so nothing to set up or clean up
            return undefined;
        }
        let unloadResolve;
        let timeout;
        const timeoutPromise = new Promise((res, rej) => {
            unloadResolve = res;
            timeout = setTimeout(() => {
                setLazyModule({ path, isTimeout: true });
                rej();
            }, LAZY_LOAD_TIMEOUT_MS);
        });
        const importPromise = lazyLoader();
        Promise.race([importPromise, timeoutPromise])
            .then((module) => {
                clearTimeout(timeout);
                module && setLazyModule({ path, module });
            })
            .catch((error) => {
                if (!lazyModule.isTimeout) {
                    setLazyModule({ path, error });
                }
            });
        return () => {
            clearTimeout(timeout);
            unloadResolve(null);
        };
    }, [path, lazyLoader, setLazyModule, lazyModule?.isTimeout]);

    return lazyModule;
};

const getLazyContents = ({ lazyModule, path, isFullPage }) => {
    let contents;
    if (!lazyModule || lazyModule.path !== path) {
        contents = <PageLoader isFullPage={isFullPage} />;
    } else {
        if (lazyModule.module && lazyModule.module.default) {
            const LazyPage = lazyModule.module.default;
            contents = <LazyPage />;
        } else {
            if (lazyModule.error) {
                logger.error('There was an error lazy-loading a route', { path, error: lazyModule.error });
            } else {
                logger.error('A lazy-loaded route did not have a default export or there was a timeout in loading the module.', {
                    path,
                    module: lazyModule.module,
                });
            }
            if (isFullPage) {
                if (lazyModule.isTimeout) {
                    contents = <ServerBusyErrorPage/>;
                } else {
                    contents = <ErrorPage />;
                }
            } else {
                throw new Error('There was an error lazy-loading a sub-route');
            }
        }
    }
    return contents;
};

const LazyRouteContents = ({ lazyLoader, path, isFullPage, titleLocaleKey }) => {
    useSetRouteHelpKey(path);
    useSetBrowserTitleLocaleKey(titleLocaleKey);

    const lazyModule = useLazyLoader({ path, lazyLoader });

    let contents = getLazyContents({ lazyModule, path, isFullPage });

    if (isFullPage) {
        contents = <RouteErrorBoundary path={path}>{contents}</RouteErrorBoundary>;
    }
    return (
        <>
            {contents}
        </>
    );
};

LazyRouteContents.propTypes = {
    lazyLoader: PropTypes.func.isRequired,
    path: PropTypes.string,
    isFullPage: PropTypes.bool,
    titleLocaleKey: PropTypes.string,
};

export default LazyRouteContents;
