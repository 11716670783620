/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

import { useLocalizationPrefetch as useAPIErrorMessagePrefetch } from '~components/alerts/APIErrorMessage';
import Footer from '~components/page/Footer';
import { fixURL } from '~instance';

import { useLocalizationPrefetch as useErrorPagePrefetch } from './errorPages/ErrorPage';
import PageNotFound, { useLocalizationPrefetch as usePageNotFoundPrefetch } from './errorPages/PageNotFound';

const useLocalizationPrefetch = () => {
    // We want the error pages to show properly, including when they show because the server went down
    useErrorPagePrefetch();
    usePageNotFoundPrefetch();
    useAPIErrorMessagePrefetch();
};

const ScrollOnRouteChange = () => {
    const { pathname } = useLocation();
    const [prevPath, setPrevPath] = useState(pathname);
    useEffect(() => {
        if (!(pathname.startsWith('/catalog') && prevPath.startsWith('/catalog'))) {
            window.scrollTo(0, 0);
        }
        setPrevPath(pathname);
    }, [pathname, prevPath, setPrevPath]);
    return null;
};

window.history.scrollRestoration = 'auto';


const RootRouter = ({ header, children }) => {
    useLocalizationPrefetch();
    return (
        <Router basename={fixURL(__ROLE_PATH__)}>
            <ScrollOnRouteChange />
            {header}
            <Routes>
                {children}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Footer />
        </Router>
    );
};

RootRouter.propTypes = {
    header: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
};

export default RootRouter;
