/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import { automationIDPropTypes } from '../id/AutomationID';

const Heading = ({ automationID, level, children, ...hProps }) => {
    const H = `h${level}`;
    return (
        <H id={automationID?.id} {...hProps}>
            {children}
        </H>
    );
};

Heading.propTypes = {
    automationID: automationIDPropTypes,
    level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
    children: PropTypes.node.isRequired,
};

export default Heading;
