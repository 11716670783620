/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import useSmartImageBackgroundImageCSS from '~components/images/useSmartImageBackgroundImageCSS';

import FooterCopyrightLine from './FooterCopyrightLine';
import FooterCustomColumns from './FooterCustomColumns';

const Footer = () => {
    const backgroundImage = useSmartImageBackgroundImageCSS('footerBG');
    return (
        <footer className="site-footer" style={{ backgroundImage }}>
            <div className="container">
                <FooterCustomColumns />
                <FooterCopyrightLine />
            </div>
        </footer>
    );
};

export default Footer;
