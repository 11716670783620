/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { get as fetchAddressFields } from '~api/common/address/addressFields';
import { getLogger } from '~utils/logging';

import clientSideCache from '../helpers/clientSideCache';

// Address Fields are just which fields are enabled, and the Company, Department, and Location dropdown values.
// None of that information should be sensitive, so it caching it client-side shouldn't be a security concern.
const CACHE_KEY = 'addressFields';
const initialState = clientSideCache.load(CACHE_KEY, {});

const logger = getLogger(Symbol('CommonStore:Actions:AddressFields'));

// Cache for 1 minute (60k milliseconds), to minimize spammy-ness, but still get latest data
const CACHE_DURATION_MS = 60_000;

const PENDING_UPDATES = new Map();

// Same as with the thunk (see below), a single variable is passed into here. The same one that was passed into the thunk creator.
// getState is provided automatically because it's a condition
const isAddressFieldUpdateNeeded = (context, { getState }) => {
    const state = getState();
    const cachedAddressFields = state.addressFields[context];
    if (logger.isDebugEnabled()) {
        logger.debug(`cachedAddressFields[${context}]`, cachedAddressFields);
    }
    // We check the last time this state for addressFields was updated, lastUpdated is something we do ourselves.
    // We return a false if it was updated not that long ago, or if we are currently getting something from the API
    // False means we do not proceed with dispatching the action to get data from the API
    // True means we proceed (technically, anything other than explicit false means proceed, but an explicit true
    // is preferred as it is more readable)
    if (cachedAddressFields && cachedAddressFields.lastUpdated > Date.now() - CACHE_DURATION_MS) {
        logger.info(
            `Not updating AddressFields[${context}] - we had a recent cached copy, lastUpdated[${cachedAddressFields.lastUpdated}]`
        );
        return false;
    }
    if (PENDING_UPDATES.get(context)) {
        logger.info(`Not updating AddressFields[${context}] - we're already processing an update`);
        return false;
    }
    return true;
};

// The slice below this thunk is created and has reducers ready to handle any response from a thunk.
// But this thunk itself is the driving action. A thunk will handle the API call itself, wait for a result, and then
// return that result to the reducer. Because these functions are dispatched directly from the components we
// name-export this. The reducer itself is a default export which will be used when creating the store.

// However, this is a complicated thunk that sets a lastUpdated, packages the context with the addressFields
// so the reducer knows where in the state to update, and has a condition to evaluate if it needs to be fetched at all.
// There are MANY cases where we want a simple thunk that just gets data and returns it. That's where
// createSimpleAPIAsyncThunk comes in. It avoids having to write out an entire thunk by building a simple one
// for you. Just give it a string name and an API function to call.
export const getAddressFields = createAsyncThunk(
    // The NAME is used in creating the behind the scenes 'requested', 'pending', 'success' constants we used to have.
    // The importance of the name is uniqueness and also logging. You can see these names in the "Redux Dev Tools"
    // chrome extension when debugging the application
    // All async thunks should start with `{sliceName}/` for consistency and to make debugging easier.
    'addressFields/get',
    // The CONTEXT is a variable that can be passed into the getAddressFields() function from the components. You can
    // only pass in *one* variable when using createAsyncThunk as your creator for a function. If you need to pass
    // more than one variable you can package them in a single object and pass that instead.

    // The REJECTWITHVALUE is a function you can call with an object which gives it access to the
    // 'getAddressFields.rejected' addCase. Returning this function essentially says there was a failure
    // and we should be handling this in the rejected reducer, not the fulfilled reducer.
    async (context, { rejectWithValue }) => {
        PENDING_UPDATES.set(context, true);
        try {
            const addressFields = await fetchAddressFields(context);
            // We set the lastUpdated ourselves which we will refer to later to see if the cache is new enough
            // in our condition below
            addressFields.lastUpdated = Date.now();
            if (logger.isDebugEnabled()) {
                logger.debug(`Got new AddressFields[${context}]`, addressFields);
            }
            // This is the data that will be processed by the reducer as the "action.payload"
            return {
                context,
                addressFields,
            };
        } catch (error) {
            // There was an error, place this data in the action.payload and trigger the rejected reducer
            // We pass `error.message` instead of the full `error` because redux requires the payloads
            // to be serlializable, and errors are not serializable.
            return rejectWithValue({
                context,
                error: error.message,
            });
        } finally {
            PENDING_UPDATES.set(context, false);
        }
    }, {
        // A condition determines if the dispatch of this action should even be executed
        // In this case we are determining if the address fields are already cached and we can save an API call
        condition: isAddressFieldUpdateNeeded,
    }
);

const addressFieldsSlice = createSlice({
    // This will be used as the prefix for any generated action types if standard reducers are added.
    // This particular slice (and actually most of our slices) doesn't have any, since we are using async thunks,
    // so it's not technically important... but for consistency, to make debugging easier, and to minimize future
    // development risks, this name should always match the name of the file, which should also match the name
    // used in the `reducer` object passed to `createStore`.
    // Following that practice will ensure that the name seen here is also the name of the state when accessing via useSelector...
    // useSelector((state) => state.addressFields[context]);
    name: 'addressFields',

    // The initial value of the state. In this case a blank object that will soon hold addressFields for
    // different contexts. A map of String -> Object.
    initialState,

    // Reducers are used when you want to update the state right now - and there is no need to access some external API
    // to do this. It's synchronous. So most likely it will be used in the case when we are caching a value on the client-side
    // and don't need to save it to the Firebird database or otherwise send details to the server.
    reducers: {
        // No standard reducers
    },

    // Extra Reducers are used when you want to asynchronously update the state - meaning you most likely you need to call an API
    // to get the data first, then these reducers can decide how to handle the response.
    extraReducers: (builder) => {
        // Once the thunk above fetches the data from the API the reducer needs to determine how to handle it
        // Each case is handled here. We are addressing the "fulfilled" case for the getAddressFields thunk
        // But we could add cases for handling the "pending" and "rejected" results as well, like so:
        // --- builder.addCase(getAddressFields.pending, (state, action) => {
        // --- builder.addCase(getAddressFields.rejected, (state, action) => {
        builder.addCase(getAddressFields.fulfilled, (state, action) => {
            // The payload was repackaged in the thunk in a new object so more than the addressFields could be passed back.
            // The context was passed back to so we can update only that part of the state
            const { context, addressFields } = action.payload;
            // Behind the scenes Immer is creating a copy of our state, updating it, and then saving it
            // So all we need to do is just update the state var here directly
            // If we wanted to fully replace the state, as opposed to just changing part of it, we could return a brand new
            // object instead of modifying state.
            state[context] = addressFields;
            clientSideCache.save(CACHE_KEY, state);
        });
    },
});

export default addressFieldsSlice.reducer;
