/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * H4 tags
 * {h4}Text to be heading level 4{/h4}
 */
export default new SimpleTag(
    'h4',
    (key, contents) => {
        return <h4 key={key}>{contents}</h4>;
    },
    (contents) => contents
);
