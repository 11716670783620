/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useMemo } from 'react';

const useMiddleEntryHeaderOptions = (isLocked) => {
    const searchOptions = null;
    const toolbarOptions = null;
    const mobileMenuOptions = null;
    return useMemo(
        () => ({
            isLocked,
            searchOptions,
            toolbarOptions,
            mobileMenuOptions,
        }),
        [searchOptions, toolbarOptions, mobileMenuOptions, isLocked]
    );
};

export default useMiddleEntryHeaderOptions;
