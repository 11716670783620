/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useId } from 'react';

const useUniqueID = (prefix) => {
    // useId returns ids that look like ':r29:' - this is fine in many places, but the colon causes a problem when the ID needs to be referenced
    // in a css `url()` rule (ex: svg filters)
    // To avoid this problem, while still effectively guaranteeing the resulting ID is unique, we change it to `___r29___`
    const suffix = useId().replace(/:/g, '___');
    return `${prefix}${suffix}`;
};

export default useUniqueID;
