/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import { fixURL } from '~instance';

const LegacyImage = ({ src, alt, ...imgProps }) => {
    return (
        <img draggable={false} src={fixURL(src)} alt={alt} {...imgProps} />
    );
};

LegacyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default LegacyImage;