/* eslint-disable jsx-a11y/aria-props */
/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import FeatherIcon from '~components/images/icons/FeatherIcon';
import localizedStringOrNode, { localizedStringOrNodePropTypes } from '~components/text/LocalizedStringOrNode';
import useLocalizationTextFormatted from '~components/text/useLocalizationTextFormatted';
import { getLogger } from '~utils/logging';

import { automationIDPropTypes, getAutomationID, getExtraAutomationID } from '../id/AutomationID';
import IDList from '../id/IDList';
import MappingID from '../id/MappingID';
import { rootAutomationIDPropTypes } from '../id/RootAutomationID';
import TextInput from '../inputs/TextInput';

const logger = getLogger(Symbol('Components:Page:Header:HeaderSearch'));

const getDefaultCategory = (categories, defaultCategoryKey) => {
    if (!categories || categories.length === 0) {
        return null;
    }
    let defaultCategory = categories.find((category) => category.key === defaultCategoryKey);
    if (!defaultCategory) {
        defaultCategory = categories[0];
    }
    return defaultCategory;
};

const CategoriesMenu = ({ automationID, categories, selectedCategory, setSelectedCategory }) => {
    const ariaDescription = useLocalizationTextFormatted('ORDERING_UI.ARIA.HEADER__CATALOG_SEARCH_FILTERS');

    if (!categories || categories.length === 0) {
        return null;
    }
    const buttonAutomationID = getExtraAutomationID(automationID, 'button');

    const ids = [];
    return (
        <UncontrolledDropdown className="btn-group categories-btn">
            <DropdownToggle id={buttonAutomationID.id} aria-description={ariaDescription} caret>{selectedCategory && localizedStringOrNode(selectedCategory.label)}</DropdownToggle>
            <DropdownMenu>
                {categories.map((category) => {
                    const optionAutomationID = getExtraAutomationID(automationID, 'option', category.key);
                    ids.push(category.key);
                    return (
                        <>
                            <DropdownItem id={optionAutomationID.id} key={category.key} onClick={() => setSelectedCategory(category)}>
                                {localizedStringOrNode(category.label)}
                            </DropdownItem>
                            {!isNaN(category.key) && <MappingID label="CategoryIDs" name={category.label} id={category.key} />}
                        </>
                    );
                })}
                <IDList label="CategoryIDs" ids={ids} />
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

CategoriesMenu.propTypes = {
    automationID: automationIDPropTypes,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: localizedStringOrNodePropTypes,
        })
    ),
    selectedCategory: PropTypes.shape({
        label: localizedStringOrNodePropTypes,
    }),
    setSelectedCategory: PropTypes.func,
};

const MemoizedCategoriesMenu = memo(CategoriesMenu);

const HeaderSearch = ({
    rootID,
    hiddenOnMobile,
    categories,
    defaultCategoryKey,
    onSearch,
    placeholderTextKey,
    searchAltTextKey,
    currentSearch,
    currentCategoryKey,
}) => {
    const defaultCategory = getDefaultCategory(categories, defaultCategoryKey);
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory);

    useEffect(() => {
        if (currentCategoryKey) {
            const currentCategory = categories.find((category) => category.key === currentCategoryKey);
            if (currentCategory) {
                setSelectedCategory(currentCategory);
            }
        }
    }, [currentCategoryKey, categories]);

    // Saved search is currently set in the ordering-specific useBasicSearchOptions.js
    // All other roles simply ignore this and fallback to default
    const [search, setSearch] = useState(currentSearch);

    useEffect(() => {
        setSearch(currentSearch);
    }, [currentSearch]);

    const onInput = useCallback(
        (event) => {
            if (event) {
                setSearch(event.target.value);
            }
        },
        [setSearch]
    );

    const searchPlaceholder = useLocalizationTextFormatted(placeholderTextKey);
    const searchAlt = useLocalizationTextFormatted(searchAltTextKey);
    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (logger.isDebugEnabled()) {
                logger.debug(`HeaderSearch submitted`, { selectedCategory, search });
            }
            onSearch({ categoryKey: selectedCategory.key, search });
        },
        [selectedCategory, search, onSearch]
    );

    const wrapClasses = ['search-box-wrap'];
    if (hiddenOnMobile) {
        wrapClasses.push('hidden-on-mobile');
    }

    const ariaLabel = useLocalizationTextFormatted('ORDERING_UI.ARIA.HEADER__ITEM_SEARCH');
    const searchAutomationID = getAutomationID(rootID, 'searchInput');
    const searchCatalogDropdownAutomationID = getAutomationID(rootID, 'searchCatalogDropdown');
    return (
        <div className={wrapClasses.join(' ')}>
            <div className="search-box-inner">
                <form className="search-box" onSubmitCapture={onSubmit}>
                    <MemoizedCategoriesMenu
                        automationID={searchCatalogDropdownAutomationID}
                        categories={categories}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                    />
                    <div className="input-group">
                        <TextInput
                            ariaLabel={ariaLabel}
                            automationID={searchAutomationID}
                            placeholder={searchPlaceholder}
                            value={search}
                            setValue={setSearch}
                            onEnter={onInput}
                        />
                        <span className="input-group-btn">
                            <button type="submit" title={searchAlt}>
                                <FeatherIcon name="search" />
                            </button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    );
};

HeaderSearch.propTypes = {
    rootID: rootAutomationIDPropTypes,
    hiddenOnMobile: PropTypes.bool,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: localizedStringOrNodePropTypes,
        })
    ),
    defaultCategoryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSearch: PropTypes.func.isRequired,
    placeholderTextKey: PropTypes.string.isRequired,
    searchAltTextKey: PropTypes.string.isRequired,
    currentSearch: PropTypes.string,
    currentCategoryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // normalized catalog ID
};

export default HeaderSearch;
