/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import FeatherIcon from '~components/images/icons/FeatherIcon';
import LocalizedString from '~components/text/LocalizedString';

import { useActiveKey } from './HelpContext';
import HelpLink from './HelpLink';

const MainHelpLink = () => {
    const webHelpKey = useActiveKey();
    return (
        <HelpLink className="top-header--help-link" webHelpKey={webHelpKey}>
            <FeatherIcon name="help-circle" />
            &nbsp;
            <LocalizedString localeKey="COMMON_UI.LINK.HELP" />
        </HelpLink>
    );
};

export default MainHelpLink;
