/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { Fragment } from 'react';
import { isElement } from 'react-is';

import LocalizedString from '~components/text/LocalizedString';
import LocalizedParameter from '~components/text/rsaMessageFormat/LocalizedParameter';

import * as rawTagRenderer from './rawTagRenderer';

const renderToArray = ({ parsedTag, parameters, renderContents }, renderer) => {
    const output = [];
    if (parsedTag.tagData.length !== 0 || !parameters) {
        output.push(renderer.renderRawTag(parsedTag));
    } else {
        if (Object.prototype.hasOwnProperty.call(parameters, parsedTag.upperTagName)) {
            const replacement = parameters[parsedTag.upperTagName];
            renderReplacement(output, replacement, renderer);
        } else {
            output.push(renderer.renderRawTag(parsedTag));
        }
    }
    if (parsedTag.contents !== null) {
        output.push(renderer.renderContentsAndClose(parsedTag, renderContents));
    }
    return output;
};

const renderReplacement = (output, replacement, renderer) => {
    if (Array.isArray(replacement)) {
        let first = true;
        for (const singleReplacement of replacement) {
            if (!first) {
                output.push(renderer.renderElementParameter(', '));
            }
            first = false;
            renderSingleReplacement(output, singleReplacement, renderer);
        }
    } else {
        renderSingleReplacement(output, replacement, renderer);
    }
};

const renderSingleReplacement = (output, replacement, renderer) => {
    if (replacement instanceof LocalizedParameter) {
        output.push(renderer.renderLocalizedParameter(replacement));
    } else {
        const elementParameter = replacementToRenderableElement(replacement, renderer.allowsReactElements);
        output.push(renderer.renderElementParameter(elementParameter));
    }
};

const replacementToRenderableElement = (replacement, allowsReactElements) => {
    if (replacement === undefined || replacement === null) {
        return '';
    }
    if (typeof replacement === 'string') {
        return replacement;
    }
    if (isElement(replacement)) {
        if (allowsReactElements) {
            return replacement;
        } else {
            // This shouldn't happen in practice, but just in case there is a bug, we want to be reasonably graceful
            return '</>';
        }
    }
    if (Array.isArray(replacement)) {
        // This shouldn't happen in practice, but just in case there is a bug, we want to be reasonably graceful
        return '[]';
    }
    if (typeof replacement === 'object') {
        // This shouldn't happen in practice, but just in case there is a bug, we want to be reasonably graceful
        return '{}';
    }
    // The only thing that should get here in practice is integer numbers, but a string conversion should be reasonably
    // graceful for any other data type that gets to this point (ex. booleans, non-integer numbers)
    return '' + replacement;
};

const createElementRenderer = (keyGen) => {
    return Object.freeze({
        allowsReactElements: true,
        renderLocalizedParameter: (replacement) => (<LocalizedString key={keyGen.next} localeKey={replacement.localeKey} parameters={replacement.parameters} />),
        renderElementParameter: (replacement) => (<Fragment key={keyGen.next}>{replacement}</Fragment>),
        renderRawTag: (parsedTag) => rawTagRenderer.renderRawTag(parsedTag, keyGen),
        renderContentsAndClose: (parsedTag, renderContents) => rawTagRenderer.renderContentsAndClose(parsedTag, keyGen, renderContents),
    });
};

const TEXT_RENDERER = Object.freeze({
    allowsReactElements: false,
    renderLocalizedParameter: (replacement) => replacement.localeKey,
    renderElementParameter: (replacement) => replacement,
    renderRawTag: (parsedTag) => rawTagRenderer.renderRawTagAsText(parsedTag),
    renderContentsAndClose: (parsedTag, renderContents) => rawTagRenderer.renderContentsAsTextAndClose(parsedTag, renderContents),
});

export const render = ({ keyGen, ...args }) => {
    const output = renderToArray(args, createElementRenderer(keyGen));
    return <Fragment key={keyGen.next}>{output}</Fragment>;
};

export const renderAsText = (args) => {
    const output = renderToArray(args, TEXT_RENDERER);
    return output.join('');
};
