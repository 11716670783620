/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { set as setTimeZoneOnServer } from '~api/timeZone';

export const setTimeZone = () => {
    return async () => {
        // Nothing to actually keep track of client-side for timezone currently
        await setTimeZoneOnServer();
    };
};
