/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useIdleTimer } from 'react-idle-timer';

import { refresh as refreshSession } from '~/api/sessionRefresh';
import ConfirmModalButton from '~/components/modal/ConfirmModalButton';
import useRedirect from '~/components/routing/useRedirect';
import LocalizedString from '~/components/text/LocalizedString';
import { useCurrentUser } from '~/components/user/UserContext';
import useOnOffToggle from '~/utils/hooks/useOnOffToggle';
import Modal from '~components/modal/Modal';

const _30_SECONDS_IN_MS = 30 * 1000;
const MINUTE_IN_MS = 60 * 1000;

const TimeoutModal = () => {
    const currentUser = useCurrentUser();
    const expirationTimeMs = currentUser?.features?.login?.sessionExpirationMs || 30 * MINUTE_IN_MS;
    const promptBeforeMs = currentUser?.features?.login?.promptBeforeExpirationMs || 5 * MINUTE_IN_MS;

    const [isVisible, showModal, /* hideModal, */ toggleModal] = useOnOffToggle();
    const redirect = useRedirect();
    // This will start a timer that, after a certain period of time, will log the user out
    // Before they are logged out, they wil be presented with a prompt to let them know that their session is expiring
    // When the user takes any action (e.g. moving the mouse on the page), an empty request is sent to the server to keep the session alive
    // The 'activate' action just restarts the timer
    const { activate, pause, resume } = useIdleTimer({
        // What to do to alert the user that they are about to be logged out (i.e. show the alert modal)
        onPrompt: showModal,
        // Log the user out when the timeout is reached
        onIdle: redirect.logout().redirect,
        // How long before the timeout the onPrompt action should be taken
        // The Math.min ensures that the user has at least 1 minute leniency before being warned for inactivity
        promptBeforeIdle: Math.min(promptBeforeMs, expirationTimeMs - MINUTE_IN_MS),
        // After this time, the onIdle action is taken
        timeout: expirationTimeMs,
        // When the user takes any action, make a server request to keep the session alive
        onAction: currentUser?.userID && refreshSession,
        // Only call the onAction method once every 30 seconds, max, to prevent excess server load
        throttle: _30_SECONDS_IN_MS,
    });

    if (!currentUser?.userID) {
        pause(); // If there is no current user (i.e. logged out), then pause the timer since there's no timeout
        return null;
    }

    resume(); // Resume the timer, in case we logged out then back in

    return (
        <Modal
            title={<LocalizedString localeKey="AUTHENTICATED_UI.TITLE.SESSION_TIMEOUT" />}
            isOpen={isVisible}
            toggleModal={toggleModal}
            footer={<ConfirmModalButton
                action={activate}
                toggleModal={toggleModal}
                label={<LocalizedString localeKey="AUTHENTICATED_UI.BUTTON.SESSION_TIMEOUT__STILL_HERE" />}
            />}
        >
            <LocalizedString localeKey="AUTHENTICATED_UI.MESSAGE.SESSION_TIMEOUT" />
        </Modal>
    );
};

export default TimeoutModal;
