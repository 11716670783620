/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

export const buildStyleGuideID = (page) => {
    return buildID('styleGuide', page);
};

export const buildOrderingID = (page) => {
    return buildID('ordering', page);
};

export const buildMobileOrderingID = (page) => {
    return buildID('orderingMobile', page);
};

export const buildPublicID = (page) => {
    return buildID('public', page);
};

export const buildMobilePublicID = (page) => {
    return buildID('publicMobile', page);
};

export const buildCatalogID = (page) => {
    return buildID('catalog', page);
};

export const buildMobileCatalogID = (page) => {
    return buildID('catalogMobile', page);
};

const buildID = (role, page) => {
    const sanitizedPage = page.replace(/-/g, '_');

    const mappingName = sanitizedPage.toLowerCase();
    const idList = `${sanitizedPage}IDs`;

    return {
        role,
        page,
        mappingName,
        idList,
    };
};

export const rootAutomationIDPropTypes = PropTypes.shape({
    role: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
});