/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import normalizeColor from '~utils/colors/normalizeColor';

import { ANYTHING_BUT_CURLY_AND_QUOTE as validAttributePattern } from './attributePatterns';
import SingleAttributeTag from './singleAttributeTag';

/**
 * Changes the color of text
 * {color=primary}Text to be given the primary color{/color}
 * {color=#f00}Text to be made red{/color}
 * {color=rebeccapurple}Text to be made purple{/color}
 */
export default new SingleAttributeTag(
    'color',
    validAttributePattern,
    (key, attributeValue, contents) => {
        const color = normalizeColor(attributeValue);
        return (
            <span key={key} style={{ color }}>
                {contents}
            </span>
        );
    },
    (_attributeValue /* unused for text formatting */, contents) => contents
);
