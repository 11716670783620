/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { getLogger } from '~utils/logging';

const logger = getLogger(Symbol('Components:Header:Help:HelpContext'));

const HelpContext = createContext(null);

const useAddToArrayCallback = (setArray) => {
    return useCallback(
        (newValue) => {
            setArray((currentValues) => [...currentValues, newValue]);
        },
        [setArray]
    );
};

const useRemoveFromArrayCallback = (setArray) => {
    return useCallback(
        (newValue) => {
            setArray((curentValues) => curentValues.filter((value) => value !== newValue));
        },
        [setArray]
    );
};

const findBestKey = (activeKeys) => {
    let activeKey = activeKeys.reduce((bestKey, key) => {
        return bestKey.replace(/\*$/, key);
    }, `${__ROLE_PATH_NOSLASH__}/*`);
    if (activeKey.endsWith('/*')) {
        activeKey = activeKey.substring(0, activeKey.length - 2);
    }
    return activeKey;
};


const HelpProvider = ({ children }) => {
    const [activeKeys, setActiveKeys] = useState([]);
    const addActiveKey = useAddToArrayCallback(setActiveKeys);
    const removeActiveKey = useRemoveFromArrayCallback(setActiveKeys);
    const activeKey = findBestKey(activeKeys);

    const [browserTitleLocaleKey, setBrowserTitleLocaleKey] = useState('');
    const [browserTitleAdditionalName, setBrowserTitleAdditionalName] = useState('');

    useEffect(() => {
        logger.info(`Help activeKey: ${activeKey}`);
    }, [activeKey]);
    useEffect(() => {
        logger.debug(`Help activeKeys: ${activeKeys.join(', ')}`);
    }, [activeKeys]);

    const state = useMemo(
        () => ({
            activeKey,
            addActiveKey,
            removeActiveKey,
            setBrowserTitleLocaleKey,
            browserTitleLocaleKey,
            setBrowserTitleAdditionalName,
            browserTitleAdditionalName,
        }),
        [activeKey, addActiveKey, browserTitleAdditionalName, browserTitleLocaleKey, removeActiveKey]
    );

    return <HelpContext.Provider value={state}>{children}</HelpContext.Provider>;
};

HelpProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const useActiveKey = () => {
    const { activeKey } = useContext(HelpContext);
    return activeKey;
};

const useSetRouteHelpKey = (path) => {
    const helpKey = path;
    const { addActiveKey, removeActiveKey } = useContext(HelpContext);
    useEffect(() => {
        addActiveKey(helpKey);
        return () => {
            removeActiveKey(helpKey);
        };
    }, [helpKey, addActiveKey, removeActiveKey]);
};

// The titles for the pages are set when the route is created
const useSetBrowserTitleLocaleKey = (localeKey) => {
    const { setBrowserTitleLocaleKey, setBrowserTitleAdditionalName } = useContext(HelpContext);
    setBrowserTitleLocaleKey(localeKey);
    // New title coming in, erase the old additional name
    setBrowserTitleAdditionalName('');
};

const useGetBrowserTitleLocaleKey = () => {
    const { browserTitleLocaleKey } = useContext(HelpContext);
    return browserTitleLocaleKey;
};

// We sometimes want additional information like an order name for the Order Details page
// This information unlike a hardcoded title has to be loaded from the server though and has to be applied and the
// title updated later. So we call this method from the Page components themselves.
const useSetBrowserTitleAdditionalName = (name) => {
    const { setBrowserTitleAdditionalName } = useContext(HelpContext);
    setBrowserTitleAdditionalName(name);
};

const useGetBrowserTitleAdditionalName = () => {
    const { browserTitleAdditionalName } = useContext(HelpContext);
    return browserTitleAdditionalName;
};

export { HelpProvider, useSetRouteHelpKey, useActiveKey, useSetBrowserTitleLocaleKey, useGetBrowserTitleLocaleKey, useSetBrowserTitleAdditionalName, useGetBrowserTitleAdditionalName };
