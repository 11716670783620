/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

const DefsSVG = ({ children }) => {
    return (
        <svg height="1" width="1" style={{ position: 'fixed', left: 0, top: 0, pointerEvents: 'none' }}>
            <defs>{children}</defs>
        </svg>
    );
};

DefsSVG.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefsSVG;
