/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import useCounter from './useCounter';

/**
 * A hook to force a react component to re-render when something outside the component's state updates.
 *
 * @returns {function():void} A function that, when called, will force the react component using this hook to re-render
 */
const useForceUpdate = () => {
    const [/* count unused */, increment] = useCounter();
    return increment;
};

export default useForceUpdate;
