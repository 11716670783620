/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

class APIError extends Error {
    constructor(type, requestID, message, options) {
        super(message, options);
        this._type = type;
        this._requestID = requestID;
    }

    get type() {
        return this._type;
    }

    get requestID() {
        return this._requestID;
    }
}

APIError.TYPE = Object.freeze({
    UNEXPECTED_RESPONSE: 'UNEXPECTED_RESPONSE',
    UNEXPECTED_CLIENT_ERROR: 'UNEXPECTED_CLIENT_ERROR',
    BAD_REQUEST: 'BAD_REQUEST',
    UNAUTHORIZED: 'UNAUTHORIZED',
    NOT_FOUND: 'NOT_FOUND',
    SERVER_ERROR: 'SERVER_ERROR',
    fromStatusCode: (status) => {
        switch (status) {
            case 400:
                return APIError.TYPE.BAD_REQUEST;
            case 401:
                return APIError.TYPE.UNAUTHORIZED;
            case 404:
                return APIError.TYPE.NOT_FOUND;
            case 500:
                return APIError.TYPE.SERVER_ERROR;
        }
        return APIError.TYPE.UNEXPECTED_RESPONSE;
    },
});

export default APIError;
