/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useCallback, useState } from 'react';

/**
 * A counter state, starting at 0.
 *
 * @returns {[number, Function]} [count, increment] A counter number and a function to increment the counter
 */
const useCounter = () => {
    const [count, setCount] = useState(0);
    const increment = useCallback(() => {
        setCount((v) => v + 1);
    }, [setCount]);
    return [count, increment];
};

export default useCounter;
