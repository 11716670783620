/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSmartImage } from '~common-store/slices/smartImages';

const useSmartImage = (name) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (name) {
            // Only return an image if there was a name provided
            dispatch(getSmartImage(name));
        }
    }, [dispatch, name]);
    return useSelector((state) => (name && state.smartImages[name]) || undefined);
};

export default useSmartImage;
