/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { get as fetchShipMethods } from '~api/ordering/shipMethods';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';

export const getShipMethods = createSimpleAPIAsyncThunk('shipMethods/get', fetchShipMethods);

const shipMethodsSlice = createSlice({
    name: 'shipMethods',
    initialState: {},
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getShipMethods.fulfilled, (state, action) => {
            const orderID = action.meta.arg;
            const shipMethods = action.payload;
            state[orderID] = shipMethods;
        });
    },
});

export default shipMethodsSlice.reducer;
