/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getLocalizations } from '~common-store/slices/localization';
import useEffectDeepEqualDeps from '~utils/hooks/useEffectDeepEqualDeps';

const useLocalizations = (localeKeys) => {
    const dispatch = useDispatch();
    // It's very natural to call useLocalizations in a way that creates a new array each time, and if we used
    // the standard useEffect that would mean localeKeys looks different every time, wasting processing time.
    // useEffectDeepArrayDeps unfolds the localeKeys array, allowing this to be triggered if and only if the contents
    // of the localeKeys array changes.
    useEffectDeepEqualDeps(() => {
        if (localeKeys.length > 0) {
            dispatch(getLocalizations(localeKeys));
        }
    }, [dispatch, localeKeys]);
    return useSelector((state) => localeKeys.map((localeKey) => state.localization[localeKey]), shallowEqual);
};

export default useLocalizations;
