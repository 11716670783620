/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

export default (pattern) => {
    return (props, propName, componentName) => {
        if (!pattern.test(props[propName])) {
            return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
        }
        return undefined;
    };
};
