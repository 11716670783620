/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';
import SVG from 'react-inlinesvg';

import useUniqueID from '~/utils/hooks/useUniqueID';
import Tooltip, { PlacementPropType } from '~components/tooltip/Tooltip';
import useCallbackRef from '~utils/hooks/useCallbackRef';

import { automationIDPropTypes } from '../id/AutomationID';

import useSmartImage from './useSmartImage';

const SmartImageIMG = forwardRef(({ automationID, name, src, alt, className, style }, ref) => {
    return (
        <img
            id={automationID?.id}
            ref={ref}
            draggable={false}
            src={src}
            data-smart-image={name}
            alt={alt}
            className={className}
            style={style}
        />
    );
});

SmartImageIMG.displayName = 'SmartImageIMG';
SmartImageIMG.propTypes = {
    automationID: automationIDPropTypes,
    name: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
};


const SmartImageSVG = forwardRef(({ automationID, name, src, alt, className }, ref) => {
    const uniqueHash = useUniqueID(name);
    return (
        <SVG
            id={automationID?.id}
            innerRef={ref}
            draggable={false}
            src={src}
            data-smart-image={name}
            aria-label={alt || undefined}
            className={className}
            uniquifyIDs={true}
            uniqueHash={uniqueHash}
            // SVGs with a tooltip become focusable in some browsers if we don't explicitly make them non-focusable
            focusable={false}
            tabIndex="-1"
        />
    );
});

SmartImageSVG.displayName = 'SmartImageSVG';
SmartImageSVG.propTypes = {
    automationID: automationIDPropTypes,
    name: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const SmartImageContent = forwardRef(({ name, alt, className, automationID }, ref) => {
    const smartImage = useSmartImage(name);
    if (smartImage) {
        if (smartImage.missing) {
            return (
                <SmartImageIMG
                    automationID={automationID}
                    ref={ref}
                    name={name}
                    src="data:,"
                    alt={alt}
                    className={className}
                    style={{ lineHeight: 'initial' }}
                />
            );
        } else if (smartImage.isSVG) {
            return (
                <SmartImageSVG
                    automationID={automationID}
                    ref={ref}
                    name={name}
                    src={smartImage.src}
                    alt={alt}
                    className={className}
                />
            );
        } else {
            return (
                <SmartImageIMG
                    automationID={automationID}
                    ref={ref}
                    name={name}
                    src={smartImage.src}
                    alt={alt}
                    className={className}
                />
            );
        }
    } else {
        return <span id={automationID?.id} ref={ref} data-smart-image={name} alt={alt} className={className} />;
    }
});
SmartImageContent.displayName = 'SmartImageContent';

SmartImageContent.propTypes = {
    automationID: automationIDPropTypes,
    name: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export const showTooltip = (tooltipPlacement) => {
    return tooltipPlacement && tooltipPlacement !== 'none';
};

const SmartImageStaticAlt = ({ alt, tooltip, tooltipPlacement, ...imageProps }) => {
    const [tooltipRef, setTooltipRef] = useCallbackRef();
    if (showTooltip(tooltipPlacement)) {
        return (
            <>
                <SmartImageContent ref={setTooltipRef} alt={alt} {...imageProps} />
                <Tooltip placement={tooltipPlacement} target={tooltipRef}>
                    {tooltip || alt}
                </Tooltip>
            </>
        );
    } else {
        return <SmartImageContent ref={setTooltipRef} alt={alt} {...imageProps} />;
    }
};

SmartImageStaticAlt.propTypes = {
    automationID: automationIDPropTypes,
    alt: PropTypes.string.isRequired,
    tooltip: PropTypes.node,
    tooltipPlacement: PropTypes.oneOfType([PropTypes.oneOf(['none']), PlacementPropType]),
};

export default memo(SmartImageStaticAlt);
