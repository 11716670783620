/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */


import PropTypes from 'prop-types';

import { renderAsText } from '../text/rsaMessageFormat';

const MappingID = ({ label, name, id }) => {
    const props = {
        id,
        type: 'hidden',
    };
    props[`data-name-${label.toLowerCase()}`] = renderAsText(name);
    return <input {...props}/>;
};

MappingID.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MappingID;
