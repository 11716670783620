/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import deepCopy from '~/utils/objects/deepCopy';
import { mergedRangesCopy } from '~common-store/helpers/mergeRanges';
import { merge } from '~common-store/helpers/mergeUpdates';
import deepFreeze from '~utils/objects/deepFreeze';

/** @typedef {import('~typedefs/api/documentProfile/DocumentProfile').PageException} PageException */

/**
 * Simplifies an array of ranges, removing any that don't have details beyond the from/to.
 * Note: We aren't removing "do nothing" ranges (example: Exception range w/o paper or plex), but have an explicit null in a
 *       field because that would remove the data from updates before they are sent if we need to merge two update requests.
 *
 * @param {Array<{from: number, to: number}>} ranges
 */
const removeEmptyRanges = (ranges) => {
    for (let i = ranges.length - 1; i >= 0; i--) {
        const exceptionRange = ranges[i];
        const keys = Object.keys(exceptionRange);
        if (keys.length === 2 && keys.includes('from') && keys.includes('to')) {
            ranges.splice(i, 1);
        }
    }
};

const mergeExceptions = (source, updates) => {
    if (Array.isArray(source)) {
        if (Array.isArray(updates)) {
            const withMissingRanges = [];
            let lastTo = 0;
            for (const range of source) {
                if (lastTo + 1 !== range.from) {
                    withMissingRanges.push({
                        from: lastTo + 1,
                        to: range.from - 1,
                    });
                }
                withMissingRanges.push(range);
                lastTo = range.to;
            }
            withMissingRanges.push({
                from: lastTo + 1,
                to: Number.MAX_SAFE_INTEGER,
            });

            const mergedRanges = mergedRangesCopy(withMissingRanges, updates, merge);
            removeEmptyRanges(mergedRanges);
            return mergedRanges;
        } else {
            return source;
        }
    } else {
        const updatesCopy = deepCopy(updates);
        removeEmptyRanges(updatesCopy);
        return updatesCopy;
    }
};

/**
 * Print Option fields that shouldn't be partially updated when doing a merge, because we never expect partial updates.
 * Example: Output Type. We don't want to have to bother with explicit false values for isColor/isWide,
 *          but if we used a standard merge w/o flagging it as a full replacement, we'd have to (otherwise, replacing Color
 *          with B/W would fail to clear the isColor flag).
 */
export const printOptionsFullReplacements = deepFreeze({
    custom1: true,
    outputType: true,
    paperStock: true,
    scale: true,
    plex: true,
    frontCover: {
        paperStock: true,
        printSides: true,
    },
    backCover: {
        paperStock: true,
        printSides: true,
    },
    binding: true,
    folding: true,
    tabStock: true,
    // Note: `inserts` will be a full replacement, because the arrays don't have IDs - all updates should have every insert
    exceptions: mergeExceptions,
    colorPageRanges: 'range',
});