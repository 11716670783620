/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useLocation } from 'react-router-dom';

const usePathName = () => {
    const location = useLocation();
    return location.pathname;
};

export default usePathName;
