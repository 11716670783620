/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import localizedStringOrNode, { localizedStringOrNodePropTypes } from '~components/text/LocalizedStringOrNode';

import { automationIDPropTypes } from '../id/AutomationID';
import { rootAutomationIDPropTypes } from '../id/RootAutomationID';

import HeaderToolbar from './toolbar/HeaderToolbar';

const BottomHeaderLink = ({ page }) => {
    const className = page.isCurrent || page.isChildCurrent ? 'active' : '';
    const id = page.automationID.id;
    return (
        <li id={id} className={className}>
            <a href={page.href} onClick={page.redirect}>
                {localizedStringOrNode(page.label)}
            </a>
        </li>
    );
};

BottomHeaderLink.propTypes = {
    page: PropTypes.shape({
        redirect: PropTypes.func.isRequired,
        isCurrent: PropTypes.bool,
        isChildCurrent: PropTypes.bool,
        href: PropTypes.string.isRequired,
        label: localizedStringOrNodePropTypes,
        automationID: automationIDPropTypes,
    }),
};

const BottomHeader = forwardRef(({ rootID, pages, toolbarOptions }, ref) => {
    return (
        <div ref={ref} className="navbar">
            {pages && (
                <nav className="site-menu">
                    <ul>
                        {pages.map((page, i) => (
                            <BottomHeaderLink key={i} page={page} />
                        ))}
                    </ul>
                </nav>
            )}
            {toolbarOptions && <HeaderToolbar {...toolbarOptions} rootID={rootID} />}
        </div>
    );
});

BottomHeader.displayName = 'BottomHeader';

BottomHeader.propTypes = {
    rootID: rootAutomationIDPropTypes,
    pages: PropTypes.array,
    toolbarOptions: PropTypes.object,
};

export default BottomHeader;
