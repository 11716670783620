/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import isElementVisibleAndEnabled from './isElementVisibleAndEnabled';

const focusOn = (node, focusOnChild) => {
    if (isElementVisibleAndEnabled(node)) {
        if (node.tabIndex >= 0) {
            node.focus();
            return true;
        } else if (focusOnChild(node)) {
            return true;
        }
    }
    return false;
};

export const focusOnFirstChild = (parent) => {
    let node = parent?.firstElementChild;
    while (node) {
        if (focusOn(node, focusOnFirstChild)) {
            return true;
        }
        node = node.nextElementSibling;
    }
    return false;
};

export const focusOnLastChild = (parent) => {
    let node = parent?.lastElementChild;
    while (node) {
        if (focusOn(node, focusOnLastChild)) {
            return true;
        }
        node = node.previousElementSibling;
    }
    return false;
};
