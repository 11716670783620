/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import useForceUpdate from '~utils/hooks/useForceUpdate';

import FormattedString from '../text/FormattedString';

import { AlertType } from './AlertType';
import Toast from './Toast';

const ToastContext = createContext(null);

// Keep this in-sync with the fade-out animation delay in the css, so the element is removed right when the animation finishes
const FADE_TIMEOUT = 500;

const DEFAULT_TIMEOUT = 15_000;

let toastCount = 0;

const ToastProvider = ({ children }) => {
    const forceUpdate = useForceUpdate();
    const [toasts, setToasts] = useState([]);
    const clearToast = useCallback(
        (toast) => {
            if (!toast.fading) {
                toast.fading = true;
                // We do this so something changes that will trigger a re-render when the fading animation starts
                forceUpdate();
                setTimeout(() => {
                    setToasts((currentToasts) => currentToasts.filter((e) => e !== toast));
                }, FADE_TIMEOUT);
            }
        },
        [setToasts, forceUpdate]
    );

    const addToast = useCallback(
        ({ type, message, timeout = DEFAULT_TIMEOUT }) => {
            const key = toastCount++;
            const toast = { key, type, message, close: () => clearToast(toast), fading: false };
            setToasts((currentToasts) => [...currentToasts, toast]);
            if (timeout !== 0 && type !== AlertType.USER_ERROR && type !== AlertType.SYSTEM_ERROR) {
                // User and system errors must be closed by the user
                setTimeout(() => toast.close(), timeout);
            }
            return toast;
        },
        [setToasts, clearToast]
    );

    const value = useMemo(
        () => ({
            addToast,
        }),
        [addToast]
    );

    return (
        <ToastContext.Provider value={value}>
            {children}
            {toasts.length > 0 && (
                <div className="rsa-toast-section top">
                    {toasts.map((toast) => (
                        <Toast key={toast.key} type={toast.type} onDismiss={toast.close} fading={toast.fading}>
                            {toast.message}
                        </Toast>
                    ))}
                </div>
            )}
        </ToastContext.Provider>
    );
};

export const displayToastForAPIResponse = (addToast, response, label) => {
    if (response?.messages) {
        response.messages.map((message) => {
            addToast({
                type: message.type,
                message: (
                    <FormattedString
                        pattern={message.message}
                        source={`${label} response.messages.message`}
                    />
                ),
            });
        });
    }
};

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const useAddToast = () => {
    const { addToast } = useContext(ToastContext);
    return addToast;
};

export { ToastProvider, useAddToast };
