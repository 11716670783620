/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import LocalizedString from '../text/LocalizedString';

import mainContentID from './mainContentID';

const SkipToMainContent = () => {
    return (
        <a href={`#${mainContentID}`} className="skip-to-main">
            <span className="skip-to-main-text">
                <LocalizedString localeKey="COMMON_UI.LINK.SKIP_TO_MAIN_CONTENT" />
            </span>
        </a>
    );
};

export default SkipToMainContent;
