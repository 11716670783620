/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

export default (elem) => {
    if (!elem) {
        return false;
    }
    if (elem.disabled) {
        return false;
    }
    if (elem.tagName === 'INPUT' && elem.type === 'hidden') {
        return false;
    }
    const { visibility, display } = getComputedStyle(elem);
    if (visibility === 'hidden' || display === 'none') {
        return false;
    }
    return elem.clientWidth > 0 && elem.clientHeight > 0;
};
