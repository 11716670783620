/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { parse } from './lexer';
import { tagMap } from './tagList';
import * as tokenParameterRenderer from './tags/tokenParameterRenderer';

const toText = (parsedInput, parameters) => {
    if (parsedInput === null || parsedInput.length === 0) {
        return '';
    }
    if (typeof parsedInput === 'string') {
        return parsedInput;
    }
    const renderContents = (contents) => toText(contents, parameters);
    if (Array.isArray(parsedInput)) {
        return parsedInput.map(renderContents).join('');
    }

    const tagRenderer = tagMap.get(parsedInput.upperTagName);
    if (tagRenderer) {
        return tagRenderer.renderAsText({ parsedTag: parsedInput, renderContents });
    }
    return tokenParameterRenderer.renderAsText({
        parsedTag: parsedInput,
        parameters,
        renderContents,
    });
};

export default (input, parameters) => {
    const parsedInput = parse(input);
    return toText(parsedInput, parameters);
};
