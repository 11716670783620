/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { load as loadFromStorage, save as saveToStorage } from '~utils/clientSideStorage';

import { setDebugState } from './debug';

const STORAGE_PREFIX = 'DEBUG--';

const getStorageKey = (category, key) => `${STORAGE_PREFIX}${category}.${key}`;

export const loadDebugState = (category, key, defaultValue) => {
    const value = loadFromStorage(getStorageKey(category, key), defaultValue);
    setDebugState(category, key, value);
};

export const setDebugStateWithStorage = (category, key, value) => {
    setDebugState(category, key, value);
    saveToStorage(getStorageKey(category, key), value);
};
