/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

/**
 * Creates a not found object.  Used by pages that are unable to find the object they are expecting
 * to display.
 *
 * @returns {object} returns object with not found boolean used for display checks
 */
export const notFound = { isNotFound: true };