/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import useSmartImage from './useSmartImage';

const useSmartImageBackgroundImageCSS = (name) => {
    const bgSmartImage = useSmartImage(name);
    if (!bgSmartImage || bgSmartImage.isMissing) {
        return undefined;
    } else {
        return `url(${bgSmartImage.src})`;
    }
};

export default useSmartImageBackgroundImageCSS;
