/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

export const get = async (orderID) => {
    return api.get(`${orderID}`);
};

export const cancel = async (orderID) => {
    return api.delete(`${orderID}`);
};

export const update = async (orderID, changes, requiresPriceRecalculate) => {
    return api.patch(`${orderID}`, { changes, requiresPriceRecalculate });
};

export const paymentURL = async (orderID) => {
    return api.get(`${orderID}/paymentURL`);
};

export const cancelPayment = async (cancelPaymentID) => {
    return api.post(`cancelPayment`, { cancelPaymentID });
};

export const getList = async ({ page = 0, limit, keyword, statuses, startDate, endDate, ...extraArgs }) => {
    const extraKeys = Object.keys(extraArgs);
    if (extraKeys.length > 0) {
        throw new Error(`Unexpected parameters in orders API : ${JSON.stringify(extraKeys)}`);
    }
    return api.getList('', page, { keyword, statuses, startDate, endDate }, limit);
};

export const setCart = async (orderID) => {
    return api.patch(`${orderID}/setCart`);
};


export const reorder = async (orderID) => {
    return api.post(`${orderID}/reorder`);
};
