/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import SmartImageNoAlt from '~components/images/SmartImageNoAlt';
import SmartImageStaticAlt from '~components/images/SmartImageStaticAlt';

import { ANYTHING_BUT_CURLY_AND_QUOTE } from './attributePatterns';
import MultiAttributeToken from './multiAttributeToken';

/**
 * Shows a smartImage.
 * Single attribute form:
 *   {smartImage=name} => Shows the smartImage with the given name
 * Multi-attribute form:
 *   {smartImage name=name alt=Alt text}
 *   - name: Required, name for the smartImage
 *   - alt: Optional alt text (text for screen readers only)
 *
 * See also image
 */
export default new MultiAttributeToken(
    'smartImage',
    [
        {
            name: 'name',
            pattern: ANYTHING_BUT_CURLY_AND_QUOTE,
            allowAsSingle: true,
            isRequired: true,
        },
        {
            name: 'alt',
            pattern: ANYTHING_BUT_CURLY_AND_QUOTE,
        },
    ],
    (key, attributes) => {
        if (attributes.alt) {
            return <SmartImageStaticAlt key={key} name={attributes.name} alt={attributes.alt} />;
        } else {
            return <SmartImageNoAlt key={key} name={attributes.name} />;
        }
    },
    () => ''
);
