/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */


const MIN_Z_INDEX = 5000;
const Z_INDEX_INCREMENT = 100;
const stack = new Set();

class ZIndex {
    constructor() {
        this.zIndex = -1;
    }

    initialize() {
        if (stack.has(this)) {
            return undefined;
        }
        stack.add(this);
        let maxZIndex = MIN_Z_INDEX - Z_INDEX_INCREMENT;
        for (const elem of stack) {
            maxZIndex = Math.max(maxZIndex, elem.zIndex);
        }
        this.zIndex = maxZIndex + Z_INDEX_INCREMENT;
        return this.zIndex;
    }

    clear() {
        this.zIndex = -1;
        stack.delete(this);
    }
}

export default ZIndex;
