/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

export default class LocalizedParameter {
    constructor(localeKey, parameters) {
        this._localeKey = localeKey;
        this._parameters = parameters;
    }

    get localeKey() {
        return this._localeKey;
    }

    get parameters() {
        return this._parameters;
    }
}
