/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { memo } from 'react';

import SmartImageStaticAlt from './SmartImageStaticAlt';

const SmartImageNoAlt = ({ ...props }) => {
    return <SmartImageStaticAlt alt="" {...props} />;
};

export default memo(SmartImageNoAlt);
