/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef, useCallback } from 'react';

import withAPICall from './withAPICall';

const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Spacebar') {
        e.target.click();
    }
};

/**
 * A container with a clickable action.
 * Note: See FeatherIcon and MaterialIcon for ContainerWithActions that contain only an icon, to ensure proper accessibility.
 */
const ContainerWithAction = forwardRef(({
    children, className, action, inline, notTabbable,
    role = 'button', indicateDisabled, ariaLabel, ariaDescription, title,
    ...containerProps
}, ref) => {
    const onClick = useCallback(
        (e) => {
            // Dont jump to top of page and don't click enclosing containers
            e.preventDefault();
            e.stopPropagation();
            action();
        },
        [action]
    );

    const classNames = ['container-action'];
    if (className) {
        classNames.push(className);
    }
    if (inline) {
        classNames.push('container-action-inline');
    }

    const accessibilityAttributes = [];

    if (ariaLabel) {
        // Attributes placed on an HTML element must be the standard attribute WITH hyphens
        accessibilityAttributes['aria-label'] = ariaLabel;
    }

    if (ariaDescription) {
        accessibilityAttributes['aria-description'] = ariaDescription;
    }

    if (!action) {
        return (
            <div
                ref={ref}
                role={indicateDisabled ? role : undefined}
                aria-disabled={indicateDisabled ? true : undefined}
                title={title}
                className={classNames.join(' ')}
                {...accessibilityAttributes}
                {...containerProps}
            >
                {children}
            </div>
        );
    }

    return (
        <div
            ref={ref}
            className={classNames.join(' ')}
            title={title}
            onClick={onClick}
            onKeyDown={onKeyDown}
            role={role}
            tabIndex={notTabbable ? '-1' : '0'}
            {...accessibilityAttributes}
            {...containerProps}
        >
            {children}
        </div>
    );
});

ContainerWithAction.displayName = 'ContainerWithAction';

ContainerWithAction.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    action: PropTypes.func,
    inline: PropTypes.bool,
    notTabbable: PropTypes.bool,
    role: PropTypes.string,
    ariaLabel: PropTypes.string,
    ariaDescription: PropTypes.string,
    title: PropTypes.string,
    indicateDisabled: PropTypes.bool,
};

export default ContainerWithAction;

/**
 * A specialized button that will make an API call and execute actions for success cases
 * Also it will disable the button while the API call is in progress.
 */
export const ContainerWithAPIAction = withAPICall(ContainerWithAction);
