/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import deepEqual from 'fast-deep-equal';

import deepCopy from '~/utils/objects/deepCopy';

/**
 * @typedef Range
 * @property {number} from The start of the range
 * @property {number} to The end of the range
 */

/**
 * Updates a given range with provided new ranges (returning a new range array).
 *
 * @param {Array<Range>} sourceRanges - The source ranges to modify (must be complete and sequential w/o duplicates)
 * @param {Array<Range>} updatedRanges - The update to apply
 * @param {function(Range, Range):void} merge - The function to use to merge the ranges
 * @returns {Array<Range>} The modified ranges
 */
export const mergedRangesCopy = (sourceRanges, updatedRanges, merge) => {
    let result = deepCopy(sourceRanges);
    for (const updatedRange of updatedRanges) {
        result = mergedRangeCopy(result, updatedRange, merge);
    }
    return simplifyRanges(result);
};

/**
 * Updates a given range with provided singular range (returning a new range array).
 *
 * @param {Array<Range>} sourceRanges - The source ranges to modify (must be complete and sequential w/o duplicates)
 * @param {Range} updatedRange - The update to apply
 * @param {function(Range, Range):void} merge - The function to use to merge the ranges
 * @returns {Array<Range>} The modified ranges
 */
const mergedRangeCopy = (sourceRanges, updatedRange, merge) => {
    const resultingRanges = [];
    for (const range of sourceRanges) {
        if (updatedRange.from > range.to || updatedRange.to < range.from) {
            // The updates are completely outside this range
            resultingRanges.push(deepCopy(range));
        } else {
            if (updatedRange.from > range.from) {
                // This range starts before the updates, so include the start of this range unchanged
                const sourceCopy = deepCopy(range);
                sourceCopy.to = updatedRange.from - 1;
                resultingRanges.push(sourceCopy);
            }

            // Add the overlapping details
            const overlappingRange = deepCopy(range);
            merge(overlappingRange, updatedRange);
            overlappingRange.from = Math.max(range.from, updatedRange.from);
            overlappingRange.to = Math.min(range.to, updatedRange.to);
            resultingRanges.push(overlappingRange);

            if (updatedRange.to < range.to) {
                // The updates end before this range, so include the remainder of this range unchanged
                const sourceCopy = deepCopy(range);
                sourceCopy.from = updatedRange.to + 1;
                resultingRanges.push(sourceCopy);
            }
        }
    }
    return resultingRanges;
};

const simplifyRanges = (sourceRanges) => {
    let lastRange = undefined;
    const result = [];
    for (const range of sourceRanges) {
        if (lastRange === undefined) {
            lastRange = range;
            result.push(range);
        } else {
            if (lastRange.to + 1 === range.from && rangesHaveSameDetails(lastRange, range)) {
                lastRange.to = range.to;
            } else {
                lastRange = range;
                result.push(range);
            }
        }
    }
    return result;
};

const rangesHaveSameDetails = (rangeA, rangeB) => {
    // We don't use the from/to details, but we also don't want them in the details we compare since we know they're different.
    // eslint-disable-next-line no-unused-vars
    const { from: fromA, to: toA, ...rangeADetails } = rangeA;
    // eslint-disable-next-line no-unused-vars
    const { from: fromB, to: toB, ...rangeBDetails } = rangeB;
    return deepEqual(rangeADetails, rangeBDetails);
};