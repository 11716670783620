/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import HighlightedText from '~components/text/HighlightedText';

import SimpleTag from './simpleTag';

/**
 * Highlights text (bold + primary color)
 * {highlight}Text to highlight{/highlight}
 */
export default new SimpleTag(
    'highlight',
    (key, contents) => {
        return <HighlightedText key={key}>{contents}</HighlightedText>;
    },
    (contents) => contents
);
