/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { getLogger } from 'loglevel';
import PropTypes from 'prop-types';

const logger = getLogger(Symbol('AutomationID'));

const ERROR_ON_INVALID_ID = false;

export const automationIDPropTypes = PropTypes.shape({
    id: PropTypes.string.isRequired,
});


export const getAutomationID = (rootID, element, databaseID) => {
    let id;
    let mappingName;
    let idList;

    if (!rootID) {
        id = null;
        mappingName = null;
        idList = null;
    } else if (!rootID.role) {
        if (ERROR_ON_INVALID_ID) {
            logger.warn('Invalid ID found for [' + rootID + '] Make sure you are using the build() functions in RootAutomationID.js!');
            return null;
        }

        id = `${rootID}`;
        mappingName = `${rootID}`;
        idList = `${rootID}`;
    } else {
        const sanitizedRole = rootID.role.replace(/-/g, '_');
        const sanitizedPage = rootID.page.replace(/-/g, '_');
        const sanitizedElement = element.replace(/-/g, '_');

        const bracketedID = getBracketedID(databaseID);

        id = `${sanitizedRole}__${sanitizedPage}__${sanitizedElement}${bracketedID}`;

        mappingName = element.toLowerCase();
        idList = `${element}IDs`;
    }

    return {
        id,
        mappingName,
        idList,
        with: (databaseID) => ({ id: `${id}${getBracketedID(databaseID)}` }),
        rootID,
    };
};

const getBracketedID = (bracketedID) => {
    return bracketedID ? `[${bracketedID}]` : '';
};

export const getExtraAutomationID = (automationID, extra, databaseID) => {
    if (!automationID) {
        if (ERROR_ON_INVALID_ID) {
            logger.warn('Invalid extra ID found for [' + extra + '] Make sure you are using the getAutomationID() function!');
        }
    }
    const bracketedID = databaseID === undefined ? '' : `[${databaseID}]`;
    const extractedAutomationID = automationID?.id;

    return {
        id: `${extractedAutomationID}__${extra}${bracketedID}`,
        rootID: automationID?.rootID,
    };
};