/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */


import api from './api';

export const get = async () => {
    return api.get('ldapAccountValues');
};

export const createAccountForLDAP = async ({ passwordReentry }) => {
    return api.post('ldapAccountCreation', { passwordReentry });
};

export const transferAccountForLDAP = async ({ previousUserID, comments }) => {
    return api.post('ldapAccountTransfer', { previousUserID, comments });
};

export const ldapAccountCancel = async () => {
    return api.post('ldapAccountCancel');
};

