/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import BrowserTitle from '~/components/page/BrowserTitle';
import Header from '~components/header/Header';

import useBottomHeaderOptions from './useBottomEntryHeaderOptions';
import useMiddleHeaderOptions from './useMiddleEntryHeaderOptions';
import useTopHeaderOptions from './useTopEntryHeaderOptions';

const EntryHeader = ({ isLocked }) => {
    const topHeaderOptions = useTopHeaderOptions();
    const middleHeaderOptions = useMiddleHeaderOptions(isLocked);
    const bottomHeaderOptions = useBottomHeaderOptions();
    return (
        <>
            <BrowserTitle/>
            <Header
                topHeaderOptions={topHeaderOptions}
                middleHeaderOptions={middleHeaderOptions}
                bottomHeaderOptions={bottomHeaderOptions}
            />
        </>
    );
};

EntryHeader.propTypes = {
    isLocked: PropTypes.bool,
};

export default EntryHeader;
