/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { memo } from 'react';
import { Alert as BAlert } from 'reactstrap';

import { AlertType } from './AlertType';

const typeToColor = new Map();
typeToColor.set(AlertType.MUTED, 'default');
typeToColor.set(AlertType.PRIMARY, 'primary');
typeToColor.set(AlertType.SECONDARY, 'secondary');
typeToColor.set(AlertType.SUCCESS, 'success');
typeToColor.set(AlertType.INFORMATION, 'info');
typeToColor.set(AlertType.USER_ERROR, 'warning');
typeToColor.set(AlertType.SYSTEM_ERROR, 'danger');

const Alert = ({ type, onDismiss, children }) => {
    return (
        <BAlert color={typeToColor.get(type)} toggle={onDismiss}>
            {children}
        </BAlert>
    );
};

Alert.propTypes = {
    type: PropTypes.oneOf([...typeToColor.keys()]).isRequired,
    onDismiss: PropTypes.func,
    children: PropTypes.node.isRequired,
};

export default memo(Alert);
