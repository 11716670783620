/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { fixURL } from '~instance';
import { getLogger } from '~utils/logging';

import { useCurrentUser } from '../user/UserContext';

import RolePrefixes, { CLIENT_SIDE_PREFIX, isCurrentRole } from './RolePrefixes';
import usePathName from './usePathName';

const logger = getLogger(Symbol('Components:Routing:useRedirect'));

const removeLeadingSlash = (url) => {
    return url?.replace(/^\/+/, '');
};

const serverSideRedirect = (href, openNewWindow = false) => {
    const redirectRef = fixURL(href);
    return {
        redirect: (e) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            logger.debug(`Server-side redirect to [${redirectRef}]`);
            if (openNewWindow) {
                window.open(redirectRef, '_blank');
            } else {
                window.location.href = redirectRef;
            }
        },
        isCurrent: false,
        isChildCurrent: false,
        href: redirectRef,
    };
};

const computeRedirect = (navigate, pathName, currentUserRoles) => {
    const clientSideRedirect = (providedPage, state, rolePrefix) => {
        const page = removeLeadingSlash(providedPage);
        const historyRef = `/${page}`;
        const isCurrent = pathName === historyRef;
        const isChildCurrent = pathName.startsWith(`${historyRef}/`);
        const href = `${CLIENT_SIDE_PREFIX}${page}`;
        const redirectRef = fixURL(href);
        // Workgroup users can ONLY access the workgroup pages
        if (currentUserRoles?.includes('WORKGROUP') && (RolePrefixes.WORKGROUP !== rolePrefix)) {
            // ...except for the proxy pages
            if (!page?.startsWith('proxy')) {
                return serverSideRedirect(`${rolePrefix || ''}${page || ''}`);
            }
        }
        // The "basename" of the router is /ordering/, so we don't need to add that here
        return {
            redirect: (e) => {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                logger.debug(`Redirecting to ordering page[${page}]`);
                // Gives the ability to pass data (state) through a redirect to a new page
                navigate(historyRef, state);
            },
            isCurrent,
            isChildCurrent,
            href: redirectRef,
            internalPath: historyRef,
        };
    };

    const roleBasedRedirect = (rolePrefix) => {
        if (isCurrentRole(rolePrefix)) {
            return (page, state) => clientSideRedirect(page, state, rolePrefix);
        } else {
            return (providedPage) => {
                const page = removeLeadingSlash(providedPage);
                return serverSideRedirect(`${rolePrefix}${page}`);
            };
        }
    };

    return {
        // In case anyone is as confused to what is happening here as I was...
        // 'ordering' is essentially an alias for a function. A page is passed into ordering as
        // a parameter. The roleBasedRedirect() is a function that returns a different function.
        // The function it returns is either local(page) or serverSide(page). Since that is the final return
        // one of those functions is returned back to ordering and THATS where the page that is passed into
        // ordering is passed into. It's a function that is an alias to a function that is serving up other
        // functions. And if you call serverSide that is returning an object that has a function
        // that can be called as one of its field (redirect).
        // That's how "useRedirect.ordering('checkout').redirect();" works.
        ordering: roleBasedRedirect(RolePrefixes.ORDERING),
        publicAccess: roleBasedRedirect(RolePrefixes.PUBLIC_ACCESS),
        logout: () => serverSideRedirect('/logout/'),
        home: () => clientSideRedirect(''),
        admin: roleBasedRedirect(RolePrefixes.ADMINISTRATOR),
        approval: roleBasedRedirect(RolePrefixes.APPROVAL),
        workgroup: roleBasedRedirect(RolePrefixes.WORKGROUP),
        production: roleBasedRedirect(RolePrefixes.PRODUCTION),
        invoicing: roleBasedRedirect(RolePrefixes.INVOICING),
        site: roleBasedRedirect(RolePrefixes.SITE),
        review: roleBasedRedirect(RolePrefixes.REVIEW),
        autostock: roleBasedRedirect(RolePrefixes.AUTOSTOCK),
        styleGuide: roleBasedRedirect('/style-guide/'),
        external: serverSideRedirect,
    };
};

const useRedirect = () => {
    // TODO JAS webcrd_dev-34 Replace this with lighter-weight role-specific redirects, to avoid the overhead of regenerating this all the time
    // WARNING: DO NOT CACHE ANYTHING HERE ACROSS MULTIPLE CONTEXTS
    // navigate doesn't like it when it's used in a context other than the one it was created in
    const navigate = useNavigate();
    const pathName = usePathName();
    const currentUser = useCurrentUser();
    return useMemo(() => computeRedirect(navigate, pathName, currentUser?.roles), [navigate, pathName, currentUser?.roles]);
};

export default useRedirect;
