/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { Component } from 'react';

import { getLogger } from '~utils/logging';

import MajorErrorPage from './errorPages/MajorErrorPage';

const logger = getLogger(Symbol('Components:Routing:AppErrorBoundary'));

class AppErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        logger.error('Error caught by AppErrorBoundary', { error, info });
    }

    render() {
        if (this.state.hasError) {
            return <MajorErrorPage/>;
        }
        return this.props.children;
    }
}

AppErrorBoundary.propTypes = {
    children: PropTypes.node,
};

export default AppErrorBoundary;
