/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import getEnhancedComponentName from '~components/getEnhancedComponentName';
import useCallbackWithCleanup from '~utils/hooks/useCallbackWithCleanup';

import isClickInside, { CLICK_LISTENER_OPTIONS } from './isClickInside';

const MenuContext = createContext(null);

const useMenuContext = () => useContext(MenuContext);

const TAB_FORWARD_KEY = 'ArrowDown';
const TAB_BACKWARD_KEY = 'ArrowUp';
const EXPAND_DOWN_KEYS = ['Enter', 'Space', 'ArrowDown'];
const EXPAND_RIGHT_KEYS = ['Enter', 'Space', 'ArrowRight'];
const COLLAPSE_DOWN_KEYS = ['Escape'];
const COLLAPSE_RIGHT_KEYS = ['Escape', 'ArrowLeft'];

const menuToggleKeyListener = ({ menuActive, toggle, collapseKeys, collapseMenu, expandKeys, expandMenu }) => {
    return (e) => {
        const key = e.key;
        let handled = false;
        if (menuActive) {
            const target = e.target;
            const activeItem = target.parentNode;
            if (activeItem?.parentNode?.parentNode === toggle) {
                if (collapseKeys.includes(key)) {
                    handled = true;
                    collapseMenu();
                } else if (key === TAB_FORWARD_KEY) {
                    handled = true;
                    const nextElem = activeItem.nextSibling || activeItem.parentNode.firstChild;
                    const elemToFocus = nextElem.firstChild;
                    elemToFocus.focus();
                } else if (key === TAB_BACKWARD_KEY) {
                    handled = true;
                    const nextElem = activeItem.previousSibling || activeItem.parentNode.lastChild;
                    const elemToFocus = nextElem.firstChild;
                    elemToFocus.focus();
                }
            }
        } else if (expandKeys.includes(key)) {
            handled = true;
            expandMenu(true /* focus */);
        }
        if (handled) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
};


const getViewportFromToggle = (menuToggle) => {
    let viewport = menuToggle.parentNode;
    while (viewport && viewport.scrollHeight === viewport.clientHeight && viewport.scrollWidth === viewport.clientWidth) {
        viewport = viewport.parentNode;
    }
    return viewport;
};

const shouldReverseDirection = ({ menu, opensDown, toggleBoundingRect, viewport }) => {
    let toggleEdge;
    let menuSize;
    let viewportSize;
    menu.style.display = 'block';
    if (opensDown) {
        toggleEdge = toggleBoundingRect.bottom;
        menuSize = menu.clientHeight;
        if (viewport) {
            const viewportRect = viewport.getBoundingClientRect();
            viewportSize = viewportRect.bottom;
        } else {
            viewportSize = window.innerHeight;
        }
    } else {
        toggleEdge = toggleBoundingRect.right;
        menuSize = menu.clientWidth;
        if (viewport) {
            const viewportRect = viewport.getBoundingClientRect();
            viewportSize = viewportRect.right;
        } else {
            viewportSize = window.innerWidth;
        }
    }
    menu.style.display = '';
    return toggleEdge + menuSize > viewportSize;
};

const useExpandMenuCallback = ({
    setMenuActive,
    setAllowFocus,
    setReverseMenuDirection,
    parentMenuContext,
    menuToggle,
    menuRef,
    opensDown,
}) => {
    return useCallback(
        (focus) => {
            setMenuActive(true);
            setAllowFocus(focus);
            parentMenuContext && parentMenuContext.setSubMenuActive(true);
            if (menuToggle) {
                // TODO JAS webcrd_dev-34 Fix this to be more reliable, and update when scrolling (maybe use Popper?)
                requestAnimationFrame(() => {
                    const menu = menuRef.current;
                    if (!menu) {
                        return;
                    }
                    const toggleBoundingRect = menuToggle.getBoundingClientRect();
                    const viewport = getViewportFromToggle(menuToggle);

                    const reverseDirection = shouldReverseDirection({ menu, opensDown, toggleBoundingRect, viewport });
                    setReverseMenuDirection(reverseDirection);
                });
            }
        },
        [setMenuActive, setAllowFocus, setReverseMenuDirection, parentMenuContext, menuToggle, menuRef, opensDown]
    );
};

const useCollapseMenuCallback = ({ setMenuActive, setSubMenuActive, parentMenuContext }) => {
    return useCallback(
        (closeParents) => {
            setMenuActive(false);
            setSubMenuActive(false);
            if (parentMenuContext) {
                parentMenuContext.setSubMenuActive(false);
                if (closeParents) {
                    parentMenuContext.collapseMenu(closeParents);
                }
            }
        },
        [setMenuActive, setSubMenuActive, parentMenuContext]
    );
};

const getMenuMouseListeners = ({ onClick, menuActive, menuRef, expandMenu, collapseMenu, toggle }) => {
    if (onClick) {
        return getMenuClickListeners({ menuActive, menuRef, expandMenu, collapseMenu, toggle });
    }
    return getMenuMouseOverListeners({ expandMenu, collapseMenu });
};

const getMenuMouseOverListeners = ({ expandMenu, collapseMenu }) => {
    const mouseEnterListener = () => expandMenu();
    const mouseLeaveListener = () => collapseMenu();
    return { mouseEnterListener, mouseLeaveListener };
};

const getMenuClickListeners = ({ menuActive, menuRef, expandMenu, collapseMenu, toggle }) => {
    const toggleClickListener = () => expandMenu(true);
    const outsideClickListener = (event) => {
        if (!menuActive) {
            // The menu isn't open, nothing special to do
            return;
        }
        if (!isClickInside({ event, parent: menuRef.current })) {
            // Clicked outside the menu - close it
            collapseMenu();
            if (isClickInside({ event, parent: toggle })) {
                // The click was on the toggle... stop the event so we don't immediately re-open the menu
                event.stopPropagation();
            }
        }
    };

    return { toggleClickListener, outsideClickListener };
};

const useMenuToggleRef = ({
    expandMenu,
    collapseMenu,
    menuActive,
    menuRef,
    subMenuActive,
    expandKeys,
    collapseKeys,
    setMenuToggle,
    onClick,
}) => {
    return useCallbackWithCleanup((toggle) => {
        setMenuToggle(toggle);
        if (!toggle || subMenuActive) {
            // The toggle isn't defined yet, or we're active in the sub menu - don't add listeners.
            // No listeners were added, so nothing to cleanup
            return undefined;
        }
        const keyListener = menuToggleKeyListener({
            menuActive,
            toggle,
            collapseKeys,
            collapseMenu,
            expandKeys,
            expandMenu,
        });
        const {
            mouseEnterListener, mouseLeaveListener,
            toggleClickListener, outsideClickListener,
        } = getMenuMouseListeners({ menuActive, menuRef, expandMenu, collapseMenu, toggle, onClick });

        toggle.addEventListener('keydown', keyListener);
        toggleClickListener && toggle.addEventListener('click', toggleClickListener);
        outsideClickListener && document.addEventListener('click', outsideClickListener, CLICK_LISTENER_OPTIONS);
        mouseEnterListener && toggle.addEventListener('mouseenter', mouseEnterListener);
        mouseLeaveListener && toggle.addEventListener('mouseleave', mouseLeaveListener);
        return () => {
            toggle.removeEventListener('keydown', keyListener);
            toggleClickListener && toggle.removeEventListener('click', toggleClickListener);
            outsideClickListener && document.removeEventListener('click', outsideClickListener, CLICK_LISTENER_OPTIONS);
            mouseEnterListener && toggle.removeEventListener('mouseenter', mouseEnterListener);
            mouseLeaveListener && toggle.removeEventListener('mouseleave', mouseLeaveListener);
        };
    }, [
        expandMenu, collapseMenu,
        menuActive,
        menuRef,
        subMenuActive,
        setMenuToggle,
        collapseKeys, expandKeys,
        onClick,
    ]);
};

const withMenuContext = ({ opensDown, onClick } = {}) => {
    const expandKeys = opensDown ? EXPAND_DOWN_KEYS : EXPAND_RIGHT_KEYS;
    const collapseKeys = opensDown ? COLLAPSE_DOWN_KEYS : COLLAPSE_RIGHT_KEYS;

    return (WrappedComponent) => {
        const EnhancedComponent = (props) => {
            const parentMenuContext = useMenuContext();
            const [menuActive, setMenuActive] = useState(false);
            const [allowFocus, setAllowFocus] = useState(false);
            const [subMenuActive, setSubMenuActive] = useState(false);
            const [menuToggle, setMenuToggle] = useState(null);
            const [reverseMenuDirection, setReverseMenuDirection] = useState(false);
            const menuRef = useRef();

            const expandMenu = useExpandMenuCallback({
                setMenuActive,
                setAllowFocus,
                setReverseMenuDirection,
                parentMenuContext,
                menuToggle,
                menuRef,
                opensDown,
            });

            const collapseMenu = useCollapseMenuCallback({ setMenuActive, setSubMenuActive, parentMenuContext });

            const menuToggleRef = useMenuToggleRef({
                expandMenu,
                collapseMenu,
                menuActive,
                menuRef,
                subMenuActive,
                expandKeys,
                collapseKeys,
                setMenuToggle,
                onClick,
            });

            const context = useMemo(
                () => ({
                    isActive: menuActive,
                    allowFocus,
                    expandMenu,
                    collapseMenu,
                    subMenuActive,
                    setSubMenuActive,
                }),
                [menuActive, allowFocus, expandMenu, collapseMenu, subMenuActive, setSubMenuActive]
            );

            return (
                <MenuContext.Provider value={context}>
                    <WrappedComponent
                        {...props}
                        {...{ menuToggleRef, menuRef, menuActive, collapseMenu, reverseMenuDirection }}
                    />
                </MenuContext.Provider>
            );
        };
        EnhancedComponent.displayName = getEnhancedComponentName('WithMenuContext', WrappedComponent);
        return EnhancedComponent;
    };
};

export { withMenuContext, useMenuContext };
