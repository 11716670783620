/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * Hides text on non-mobile devices
 * {mobileOnly}Text for small screens only{/mobileOnly}
 */
export default new SimpleTag(
    'mobileOnly',
    (key, contents) => {
        return (
            <span key={key} className="hidden-sm-up">
                {contents}
            </span>
        );
    },
    () => ''
);
