/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import LocalizedString from '~components/text/LocalizedString';

const RequiredFieldIndicator = () => {
    return (
        <span className="form-required-indicator" aria-hidden="true">
            <LocalizedString localeKey="COMMON_UI.LABEL.REQUIRED_FIELD_INDICATOR" />
        </span>
    );
};

export default RequiredFieldIndicator;
