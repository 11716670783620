/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

const path = 'accountCodeTypes';

export const get = async (context) => {
    return api.get(path, { context });
};
