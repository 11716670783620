/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { memo } from 'react';

import useLocalizationTextFormatted from '~components/text/useLocalizationTextFormatted';
import { addDebugMethod } from '~utils/debug';
import useUniqueID from '~utils/hooks/useUniqueID';

// Dev Note: We can't pull the gradient def out to a common SVG (like we do for spinner) because `currentColor` will map to
// the color where the def is, as opposed to where the PlaceholderText is
const PlaceholderText = ({ placeholderFor, width }) => {
    const loadingLabel = useLocalizationTextFormatted('COMMON_UI.IMGALT.SKELETON_PLACEHOLDER_TEXT');
    const className = `placeholder-text placeholder-text--${width ? 'inline' : 'block'}`;
    const style = (width && { width: `${width}em` }) || undefined;
    const gradientID = useUniqueID('PlaceholderTextGradient');
    return (
        <div
            data-placeholder-for={placeholderFor}
            className={className}
            style={style}
            role="progressbar"
            aria-valuetext={loadingLabel}
            aria-busy="true"
            aria-live="polite"
            aria-valuemin="0"
            aria-valuemax="100"
        >
            &nbsp;
            <svg height="100%" width="100%">
                <defs>
                    <linearGradient x1="0" y1="0" x2="1" y2="0" id={gradientID} gradientTransform="translate(-1,0)">
                        <stop stopColor="currentColor" stopOpacity={0.15} offset={0} />
                        <stop stopColor="currentColor" stopOpacity={0.25} offset={0.5} />
                        <stop stopColor="currentColor" stopOpacity={0.15} offset={1} />
                        <animateTransform
                            attributeName="gradientTransform"
                            type="translate"
                            values="-1,0;1,0"
                            repeatCount="indefinite"
                            dur="2s"
                        />
                    </linearGradient>
                </defs>
                <rect rx={6} ry={6} height="100%" width="100%" fill={`url(#${gradientID})`} />
            </svg>
        </div>
    );
};

PlaceholderText.propTypes = {
    placeholderFor: PropTypes.string,
    width: PropTypes.number,
};

export default memo(PlaceholderText);

addDebugMethod('COMPONENTS', 'showPlaceholderTextFor', () => {
    document.body.classList.add('placeholder-text-debug');
});
addDebugMethod('COMPONENTS', 'hidePlaceholderTextFor', () => {
    document.body.classList.remove('placeholder-text-debug');
});
