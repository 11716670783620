/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

/**
 * Copies an object and all children recursively.
 *
 * WARNING: This only works reliably for data that can be represented as JSON.
 * Non-simple data (functions, Dates, circular references, etc) is NOT supported.
 */
const deepCopy = (object) => {
    if (typeof object === 'object' || Array.isArray(object)) {
        return JSON.parse(JSON.stringify(object));
    }
    return object;
};

export default deepCopy;