/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { Fragment } from 'react';

import ScreenReaderOnlyText from '~components/a11y/ScreenReaderOnlyText';
import Icon from '~components/images/Icon';
import VALID_LIBRARIES from '~components/images/icons/VALID_LIBRARIES';
import VALID_SHAPES from '~components/images/icons/VALID_SHAPES';

import { ANYTHING_BUT_CURLY_AND_QUOTE, BOOLEAN, parseBoolean } from './attributePatterns';
import MultiAttributeToken from './multiAttributeToken';

/**
 * Shows an icon.
 * Single attribute form:
 *   {icon=xyz} => Shows the xyz icon using the FeatherIcon font library
 * Multi-attribute form:
 *   {icon name=xyz alt=Alt text shape=circle/square filled=Y/N color=colorValue library=feather/material}
 *   - name: Required, icon name
 *   - alt: Optional alt text (text for screen readers only)
 *   - shape: Optional shape (defaults to no shape)
 *   - filled: Optional boolean flag indicating if the shape should be filled (Y) or outline (N) - unused if Shape is not set, defaults to N
 *   - color: Optional color (defaults to the color of the text the icon is used in)
 *   - library: Optional icon library to use (defaults to Feather)
 */
export default new MultiAttributeToken(
    'icon',
    [
        {
            name: 'name',
            pattern: ANYTHING_BUT_CURLY_AND_QUOTE,
            allowAsSingle: true,
            isRequired: true,
        },
        {
            name: 'alt',
            pattern: ANYTHING_BUT_CURLY_AND_QUOTE,
        },
        {
            name: 'shape',
            pattern: new RegExp(`^(?:${VALID_SHAPES.join('|')})$`),
        },
        {
            name: 'filled',
            pattern: BOOLEAN,
        },
        {
            name: 'color',
            pattern: ANYTHING_BUT_CURLY_AND_QUOTE,
        },
        {
            name: 'library',
            pattern: new RegExp(`^(?:${VALID_LIBRARIES.join('|')})$`),
        },
    ],
    (key, { name, color, shape, filled, alt, library }) => {
        return (
            <Fragment key={key}>
                <Icon
                    library={library}
                    name={name}
                    color={color}
                    shape={shape}
                    filled={parseBoolean(filled)}
                />
                {alt && <ScreenReaderOnlyText text={alt} />}
            </Fragment>
        );
    },
    () => ''
);
