/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import LocalizedString from '~components/text/LocalizedString';

const FooterCopyrightLine = () => {
    return (
        <div className="footer-copyright">
            <LocalizedString localeKey="COMMON_UI.LABEL.FOOTER_COPYRIGHT_PREFIX" /> {__COPYRIGHT_NOTICE__}{' '}
            <LocalizedString localeKey="COMMON_UI.LABEL.FOOTER_COPYRIGHT_SUFFIX" />
        </div>
    );
};

export default FooterCopyrightLine;
