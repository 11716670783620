/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import LocalizedParameter from './LocalizedParameter';

const jsxSingleParameterPropType = PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(LocalizedParameter)]);
const jsxParameterPropType = PropTypes.oneOfType([
    jsxSingleParameterPropType,
    PropTypes.arrayOf(jsxSingleParameterPropType),
]);

export const formattedStringParamtersPropType = PropTypes.oneOfType([
    PropTypes.arrayOf(jsxParameterPropType),
    PropTypes.objectOf(jsxParameterPropType),
]);

const textSingleParameterPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(LocalizedParameter)]);
const textParameterPropType = PropTypes.oneOfType([
    textSingleParameterPropType,
    PropTypes.arrayOf(textSingleParameterPropType),
]);

export const textFormattedParametersPropType = PropTypes.oneOfType([
    PropTypes.arrayOf(textParameterPropType),
    PropTypes.objectOf(textParameterPropType),
]);
