/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import accountCodeTypes from './accountCodeTypes';
import addressFields from './addressFields';
import ajaxState from './ajaxState';
import currentUser from './currentUser';
import distributionLists from './distributionLists';
import emailOptions from './emailOptions';
import localization from './localization';
import privacyPolicy from './privacyPolicy';
import shippingPolicy from './shippingPolicy.js';
import smartImages from './smartImages';
import systemMessage from './systemMessage.js';

export default {
    // These are collecting all the common reducer/slices and exporting them to the store in createStore.js
    // The names used here are the names used when trying to access something from the state
    // Ex: useSelector((state) => state.currentUser.userID);
    // For improved readability and easier debugging, the names used here should match the name of the file and the name of the slice
    accountCodeTypes,
    addressFields,
    ajaxState,
    currentUser,
    distributionLists,
    emailOptions,
    localization,
    privacyPolicy,
    shippingPolicy,
    smartImages,
    systemMessage,
};
