/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { get as fetchEmailOptions, update as sendEmailOptionsUpdate } from '~api/common/settings/emailOptions';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';
import { merge } from '~common-store/helpers/mergeUpdates';
import { createConsolidatedUpdateAsyncThunk } from '~common-store/helpers/spamProtection';
import deepFreeze from '~utils/objects/deepFreeze';

// create a simple asynchronous fetch to request the Account Email Options data from the server
export const getEmailOptions = createSimpleAPIAsyncThunk('emailOptions/getEmailOptions', fetchEmailOptions);

// freeze the state - it will only contain these variables and never any new ones
const initialState = deepFreeze({
    showOrderReceiptEmail: undefined,
    showOrderCompletedEmail: undefined,
    showOrderCompletedForBillingEmail: undefined,
    showShipmentNotificationEmail: undefined,
    showShipmentNotificationForRecipientsEmail: undefined,
    showItemExpirationEmail: undefined,
});

// create a consolidated asynchronous update to Account Email Options
// the update will pool together all changes made during the delay time before sending to server
const { thunk: updateEmailOptions } = createConsolidatedUpdateAsyncThunk(
    'emailOptions/update',
    {
        // when the checkbox change is queued, add to the pending list by calling the reducer
        onQueued: ({ id, changes }, { dispatch }) => {
            dispatch(addPendingEmailOptionsUpdate({ id, changes }));
        },
        // when the checkbox change is run, send the update to the server
        updateCall: async ({ changes }) => sendEmailOptionsUpdate(changes),
        // when the update is done, resolve by getting the response, the applied changes and the pending changes
        onUpdate: ({ response, appliedChanges, pendingChanges, resolve }) => {
            resolve({
                updatedEmailOptions: response,
                appliedChanges,
                pendingChanges,
            });
        },
        // when merging changes, use this helper merge function
        merge,
    }
);
export { updateEmailOptions };

// update the state for Account Email Options
const updateEmailOptionsState = ({ state, updatedEmailOptions, pendingChanges }) => {
    // save the values from the server to the state
    state.showOrderReceiptEmail = updatedEmailOptions.showOrderReceiptEmail !== undefined ? updatedEmailOptions.showOrderReceiptEmail : false;
    state.showOrderCompletedEmail = updatedEmailOptions.showOrderCompletedEmail !== undefined ? updatedEmailOptions.showOrderCompletedEmail : false;
    state.showOrderCompletedForBillingEmail = updatedEmailOptions.showOrderCompletedForBillingEmail !== undefined ? updatedEmailOptions.showOrderCompletedForBillingEmail : false;
    state.showShipmentNotificationEmail = updatedEmailOptions.showShipmentNotificationEmail !== undefined ? updatedEmailOptions.showShipmentNotificationEmail : false;
    state.showShipmentNotificationForRecipientsEmail = updatedEmailOptions.showShipmentNotificationForRecipientsEmail !== undefined ? updatedEmailOptions.showShipmentNotificationForRecipientsEmail : false;
    state.showItemExpirationEmail = updatedEmailOptions.showItemExpirationEmail !== undefined ? updatedEmailOptions.showItemExpirationEmail : false;
    // If there are still pending changes, optimistically assume those changes will be OK and merge changes to the state
    if (pendingChanges) {
        merge(state, pendingChanges);
    }
};

const emailOptionsSlice = createSlice({
    name: 'emailOptions',
    initialState,
    // create reducers that can merge together changes to this slice of state data
    reducers: {
        addPendingEmailOptionsUpdate: (state, action) => {
            // Optimistically assume the changes will be OK and merge the changes to the state
            const { changes } = action.payload;
            merge(state, changes);
        },
    },
    // create functions that can be run on this slice of state data based on actions taken with it
    extraReducers: (builder) => {
        builder.addCase(getEmailOptions.fulfilled, (state, action) => {
            // save the values from the server to the state and
            // check for pending changes and merge those with the state as well
            const updatedEmailOptions = action.payload;
            updateEmailOptionsState({ state, updatedEmailOptions });
        });
        builder.addCase(updateEmailOptions.fulfilled, (state, action) => {
            // after an update is complete, update the state
            const { updatedEmailOptions, pendingChanges } = action.payload;
            updateEmailOptionsState({ state, updatedEmailOptions, pendingChanges });
        });
    },
});

const { addPendingEmailOptionsUpdate } = emailOptionsSlice.actions;

export default emailOptionsSlice.reducer;
