/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import getContrastColor from './getContrastColor';

class ColorWithContrast {
    constructor(color, contrast) {
        this._color = color;
        this._contrast = contrast;
    }

    get color() {
        return this._color;
    }

    get contrast() {
        if (this._contrast === undefined) {
            this._contrast = getContrastColor(this.color);
        }
        return this._contrast;
    }
}

const SPECIAL_COLORS = new Map();
for (const color of ['primary', 'secondary', 'muted', 'success', 'info', 'warning', 'danger']) {
    const colorVar = `var(--c-${color})`;
    const contrastVar = `var(--c-${color}-contrast)`;
    SPECIAL_COLORS.set(color, new ColorWithContrast(colorVar, contrastVar));
}

/**
 * Normalizes a color string to a proper CSS representation of that color.
 * This converts special colors like `primary` into their CSS variables.
 * All other values are returned lower-case but otherwise unchanged (even if they are not valid CSS colors).
 */
const normalizeColor = (color) => {
    if (!color) {
        return undefined;
    }
    const lowerColor = color.toLowerCase();
    const specialColor = SPECIAL_COLORS.get(lowerColor);
    if (specialColor) {
        return specialColor.color;
    }
    return lowerColor;
};

export default normalizeColor;

/**
 * Normalizes a color string to a proper CSS representation of that color, and returns an object that provides both
 * the color itself and the contrasting color.
 *
 * For special colors like `primary`, this means CSS variables.
 * For all other colors, this will return CSS color values (assuming the provided color is a valid CSS color).
 */
export const normalizeColorWithContrast = (color) => {
    if (!color) {
        return undefined;
    }
    const lowerColor = color.toLowerCase();
    const specialColor = SPECIAL_COLORS.get(lowerColor);
    if (specialColor) {
        return specialColor;
    }
    return new ColorWithContrast(lowerColor);
};
