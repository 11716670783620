/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */



import { buildLazyRoute, buildRedirectRoute } from '~components/routing/RootRoute';
import RootRouter from '~components/routing/RootRouter';

import EntryHeader from './header/EntryHeader';

const lazyLogin = () => import(/* webpackChunkName: "login" */ './pages/login/LoginPage');
const lazyCreditCardReceipt = () => import(/* webpackChunkName: "creditCardReceipt" */ './pages/CreditCardReceiptPage.js');
const lazyCreditCardError = () => import(/* webpackChunkName: "creditCardError" */ './pages/CreditCardErrorPage.js');
const lazyUsageConsent = () => import(/* webpackChunkName: "usage-consent" */ './pages/UsageConsentPage.js');
const lazyLDAPAccountCreationPage = () => import(/* webpackChunkName: "LDAPAccountCreationPage" */ '../public/pages/LDAPAccountCreationPage.js');
const lazyForgotPassword = () => import(/* webpackChunkName: "forgotPassword" */ './pages/ForgotPasswordPage');
const lazyResetPassword = () => import(/* webpackChunkName: "resetPassword" */ './pages/ResetPasswordPage');
const lazyForcedPasswordChange = () => import(/* webpackChunkName: "forcedPasswordChange" */ '../public/pages/ForcedPasswordChangePage.js');
const lazyRegisterPage = () => import(/* webpackChunkName: "registerPage" */ '../public/pages/RegisterPage.js');
const lazyLDAPNewUser = () => import(/* webpackChunkName: "ldapNewUser" */ '../public/pages/LDAPNewUserPage.js');
const lazySSOAuthFailurePage = () => import(/* webpackChunkName: "SSOAuthFailure" */ '../public/pages/SSOAuthFailure.js');
const lazyLicenseAgreementPage = () => import(/* webpackChunkName: "licenseAgreement" */ '../public/pages/LicenseAgreementPage.js');
const lazySystemLockoutPage = () => import(/* webpackChunkName: "systemLockout" */ '../public/pages/SystemLockoutPage.js');


/**
 * The RootRouter for the "Public" role (pages shown prior to logging in)
 */
const App = () => {
    return (
        <RootRouter header={<EntryHeader isLocked/>}>
            {/* We avoid using the "/" path for a real page for webhelp, allowing "/{roleName}" to be a generic help fallback topic */}
            {buildRedirectRoute('', 'login')}
            {buildLazyRoute('login', lazyLogin, 'PUBLIC_ACCESS_UI.BROWSERTITLE.LOGIN')}
            {buildLazyRoute('creditCardError', lazyCreditCardError, 'PUBLIC_ACCESS_UI.BROWSERTITLE.CREDIT_CARD_ERROR')}
            {buildLazyRoute('creditCardReceipt', lazyCreditCardReceipt, 'PUBLIC_ACCESS_UI.BROWSERTITLE.CREDIT_CARD_RECEIPT')}
            {buildLazyRoute('creditCardReceipt/:receiptID', lazyCreditCardReceipt, 'PUBLIC_ACCESS_UI.BROWSERTITLE.CREDIT_CARD_RECEIPT')}
            {buildLazyRoute('usageConsent', lazyUsageConsent, 'PUBLIC_ACCESS_UI.BROWSERTITLE.USER_CONSENT')}
            {buildLazyRoute('ldapAccountCreation', lazyLDAPAccountCreationPage, 'PUBLIC_ACCESS_UI.BROWSERTITLE.LDAP_ACCOUNT_CREATION')}
            {buildLazyRoute('forgotPassword', lazyForgotPassword, 'PUBLIC_ACCESS_UI.BROWSERTITLE.FORGOT_PASSWORD')}
            {buildLazyRoute('resetPassword', lazyResetPassword, 'PUBLIC_ACCESS_UI.BROWSERTITLE.RESET_PASSWORD')}
            {buildLazyRoute('forcedPasswordChange', lazyForcedPasswordChange, 'PUBLIC_ACCESS_UI.BROWSERTITLE.FORCED_PASSWORD_CHANGE')}
            {buildLazyRoute('register', lazyRegisterPage, 'PUBLIC_ACCESS_UI.BROWSERTITLE.REGISTER')}
            {buildLazyRoute('ldapNewUser', lazyLDAPNewUser, 'PUBLIC_ACCESS_UI.BROWSERTITLE.LDAP_NEW_USER')}
            {buildLazyRoute('SSOAuthFailure', lazySSOAuthFailurePage)}
            {buildLazyRoute('licenseAgreement', lazyLicenseAgreementPage, 'PUBLIC_ACCESS_UI.BROWSERTITLE.LICENSE_AGREEMENT')}
            {buildLazyRoute('systemLockout', lazySystemLockoutPage, 'PUBLIC_ACCESS_UI.BROWSERTITLE.SYSTEM_LOCKOUT')}
        </RootRouter>
    );
};

export default App;
