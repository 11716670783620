/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { useCallback, useState } from 'react';

const useCallbackRef = () => {
    const [ref, setRef] = useState(null);
    const callback = useCallback(
        (node) => {
            setRef(node);
        },
        [setRef]
    );
    return [ref, callback];
};

export default useCallbackRef;
