/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

// The API endpoints for /api/ordering/books

/**
 * Get the book details for any book
 *
 * @param {number} bookID - The ID of the book
 * @returns {object} JSON of book's details
 */
export const get = async (bookID) => {
    return api.get(`${bookID}`);
};

/**
 * Get the list of books for the current user
 *
 * @returns {?} a scrollable Response API of books
 */
export const getBookList = async () => {
    // Accesses API at /api/ordering/books/(blank string)
    // If no argument was provided it would be /api/ordering/books/undefined
    return api.get('');
};