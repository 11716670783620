/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { get as fetchCurrentUser, update as sendUserUpdate } from '~api/currentUser';
import { createConsolidatedUpdateAsyncThunk } from '~common-store/helpers/spamProtection';

import clientSideCache from '../helpers/clientSideCache';
import createSimpleAPIAsyncThunk from '../helpers/createSimpleAPIAsyncThunk';
import { merge, mergedCopy } from '../helpers/mergeUpdates';

const CACHE_KEY = 'currentUser';
const initialState = clientSideCache.loadShortTerm(CACHE_KEY, {});

// TODO JAS webcrd_dev-34: Have some kind of polling here to keep it accurate (improving multi-tab support)?
export const getCurrentUser = createSimpleAPIAsyncThunk('currentUser/get', fetchCurrentUser);

const { thunk: updateUser } = createConsolidatedUpdateAsyncThunk(
    'currentUser/update',
    {
        onQueued: ({ changes }, { dispatch }) => {
            dispatch(addPendingUserUpdate({ changes }));
        },
        updateCall: async ({ changes }) => sendUserUpdate(changes),
        onUpdate: ({ response, appliedChanges, pendingChanges, resolve }) => {
            resolve({
                updatedUser: response,
                appliedChanges,
                pendingChanges,
            });
        },
        merge,
    }
);
const updateCurrentUser = (changes) => updateUser({ id: 'currentUser', changes });
export { updateCurrentUser };

const updateUserCache = (updatedUser) => {
    clientSideCache.saveShortTerm(CACHE_KEY, updatedUser);
    return updatedUser;
};

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        addPendingUserUpdate: (state, action) => {
            // Optimistically assume the changes will be OK
            const { changes } = action.payload;
            const userPlusPendingChanges = mergedCopy(state, changes);
            return updateUserCache(userPlusPendingChanges);
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getCurrentUser.fulfilled, (_state, action) => {
            return updateUserCache(action.payload);
        });

        builder.addCase(updateUser.fulfilled, (_state, action) => {
            const { updatedUser, pendingChanges } = action.payload;
            const updatedUserPlusPending = mergedCopy(updatedUser, pendingChanges);
            return updateUserCache(updatedUserPlusPending);
        });
    },
});

const { addPendingUserUpdate } = currentUserSlice.actions;

export default currentUserSlice.reducer;
