/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import colorString from 'color-string';

// See also: com.rocsoft.webcrd.settings.skins.colors.RGB in the main webcrd project, for a Java implementation of similar behavior

const MAX = 255.0;
const RED_FACTOR = 0.2126;
const GREEN_FACTOR = 0.7152;
const BLUE_FACTOR = 0.0722;

const COMPONENT_PERCENT_BREAKPOINT = 0.03928;
const COMPONENT_LOW_DIVISOR = 12.92;
const COMPONENT_HIGH_ADDITION = 0.055;
const COMPONENT_HIGH_DIVISOR = 1.055;
const COMPONENT_POWER = 2.4;

/**
 * We want a Contrast Ratio of at least 4.5
 * If a color's Relative Luminance is below approximately 0.18333333, it will have at least that much contrast
 * with pure white. If a colors Relative Luminance is above that value, it will have at least that much contrast
 * with pure black.
 */
const MAX_LUMINANCE_FOR_WHITE = 0.18333333;

const DARK_CONTRAST_COLOR = '#000';
const LIGHT_CONTRAST_COLOR = '#fff';

/**
 * Calculate the relative luminance of an RGB color {@link componentToFactor} {@link http://www.google.com}
 *
 * @see https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
 */
const calculateRelativeLuminance = (red, green, blue) => {
    return (
        componentToFactor(red) * RED_FACTOR +
        componentToFactor(green) * GREEN_FACTOR +
        componentToFactor(blue) * BLUE_FACTOR
    );
};

const componentToFactor = (colorComponent) => {
    const percent = colorComponent / MAX;
    if (percent <= COMPONENT_PERCENT_BREAKPOINT) {
        return percent / COMPONENT_LOW_DIVISOR;
    } else {
        return Math.pow((percent + COMPONENT_HIGH_ADDITION) / COMPONENT_HIGH_DIVISOR, COMPONENT_POWER);
    }
};

const isContrastDark = (red, green, blue) => {
    const luminosity = calculateRelativeLuminance(red, green, blue);
    return luminosity > MAX_LUMINANCE_FOR_WHITE;
};

const toRGB = (cssColor) => {
    const color = colorString.get(cssColor.toLowerCase());
    if (!color) {
        return [0, 0, 0];
    }
    return color.value.slice(0, 3);
};

const getContrastColor = (cssColor) => {
    return isContrastDark(...toRGB(cssColor)) ? DARK_CONTRAST_COLOR : LIGHT_CONTRAST_COLOR;
};

export default getContrastColor;
