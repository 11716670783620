/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { get as fetchSystemMessage } from '~api/common/settings/systemMessage.js';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';
import deepFreeze from '~utils/objects/deepFreeze';


export const getSystemMessage = createSimpleAPIAsyncThunk('systemMessage/getSystemMessage', fetchSystemMessage);


const initialState = deepFreeze({
    showSystemMessage: false,
});

const systemMessage = createSlice({
    name: 'systemMessage',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getSystemMessage.fulfilled, (state, action) => {
            state.showSystemMessage = action.payload.showSystemMessage;
        });
    },
});


export default systemMessage.reducer;
