/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */


import PropTypes from 'prop-types';

const IDList = ({ ids, label }) => {
    return <input type="hidden" id={label} value={ids}/>;

};

IDList.propTypes = {
    ids: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
};

export default IDList;