/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */


import login from './login';

// These are the names used when trying to access something from the state
// Ex: useSelector((state) => state.currentUser.userID);
// These will be combined with the common-store reducers
export default {
    login,
};
