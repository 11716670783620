/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import * as rawTagRenderer from './rawTagRenderer';

const parseSingleAttribute = (validAttributes, tagData) => {
    const attributes = {};
    const singleAttribute = validAttributes[0];
    if (!singleAttribute.allowAsSingle) {
        return null;
    }
    attributes[singleAttribute.name] = tagData.slice(1).join('');
    return attributes;
};

const parseMultiAttributes = (tagData) => {
    const attributes = {};

    // "lastValue" is the value we saw the previous loop through
    // This isn't yet part of currentName or currentValue, but will be added to one of these in the next loop
    let lastValue = null;

    // "currentName" is the value we last decided must be an attribute name
    let currentName = null;

    // "currentValue" is the data we've decided must be part of an attribute value
    let currentValue = '';

    for (const thisValue of tagData) {
        if (thisValue === '=') {
            // We saw an equals, so the last value we saw must be an attribute name
            if (currentName !== null) {
                // If we already had an attribute name, then we've also started getting a value
                // Save the value for the previous attributeName
                attributes[currentName] = currentValue.trim();
            }

            // The last value we saw must be the attribute name
            currentName = lastValue;

            // Clear out currentValue, because we're starting a new attribute
            currentValue = '';

            // Don't save the equals, because it isn't part of the attribute value
            lastValue = '';
        } else if (currentName !== null) {
            // We're in an attribute, and haven't seen an equals, so the last value we saw must be part of the attribute value
            currentValue += lastValue;

            // Save the current value as the lastValue for the next loop
            lastValue = thisValue;
        } else if (thisValue.trim() !== '') {
            // We're not in an attribute, and saw non-whitespace
            if (lastValue !== null) {
                // We already had some data, but we don't allow multi-word attribute names, so this can't be an attribute name
                // Return null since we know it's not a valid tag
                return null;
            }
            // Save the current value as the lastValue for the next loop
            lastValue = thisValue;
        }
        // else: We're not in an attribute, and saw whitespace - ignore the whitespace
    }
    if (currentName !== null) {
        currentValue += lastValue;
        attributes[currentName] = currentValue.trim();
    }
    return attributes;
};

const parseAttributes = (validAttributes, tagData) => {
    const isSingleAttribute = tagData[0] === '=';
    const attributes = isSingleAttribute
        ? parseSingleAttribute(validAttributes, tagData)
        : parseMultiAttributes(tagData);

    if (attributes === null) {
        return attributes;
    }

    for (const validAttribute of validAttributes) {
        const attributeValue = attributes[validAttribute.name];
        if (!attributeValue) {
            if (validAttribute.isRequired) {
                return null;
            }
        } else {
            if (!validAttribute.pattern.test(attributeValue)) {
                return null;
            }
        }
    }
    return attributes;
};

export default class MultiAttributeToken {
    constructor(tagName, validAttributes, renderer, textRenderer) {
        this.upperTagName = tagName.toUpperCase();
        this.validAttributes = validAttributes;
        this.renderer = renderer;
        this.textRenderer = textRenderer;
    }

    get requireClose() {
        return false;
    }

    get tagName() {
        return this.upperTagName;
    }

    render({ parsedTag, keyGen }) {
        const attributes = parseAttributes(this.validAttributes, parsedTag.tagData);
        if (attributes === null) {
            return rawTagRenderer.render(parsedTag, keyGen);
        }
        return this.renderer(keyGen.next, attributes);
    }

    renderAsText({ parsedTag }) {
        const attributes = parseAttributes(this.validAttributes, parsedTag.tagData);
        if (attributes === null) {
            return rawTagRenderer.renderAsText(parsedTag);
        }
        return this.textRenderer(attributes);
    }
}
