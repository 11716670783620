/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { get as fetchAccountCodeTypes } from '~api/common/settings/accountCodeTypes';
import { getLogger } from '~utils/logging';

const initialState = {};

const logger = getLogger(Symbol('CommonStore:Actions:AccountCodeTypes'));

// Cache for 1 minute (60k milliseconds), to minimize spammy-ness, but still get latest data
const CACHE_DURATION_MS = 60_000;

// A map of CONTEXT => (TRUE/FALSE) to see if we are already polling for that context's data
const PENDING_UPDATES = new Map();

const isAccountCodeTypesUpdateNeeded = (context, { getState }) => {
    const state = getState();
    const cachedAccountCodeTypes = state.accountCodeTypes[context];
    if (logger.isDebugEnabled()) {
        logger.debug(`cachedAccountCodeTypes[${context}]`, cachedAccountCodeTypes);
    }
    if (cachedAccountCodeTypes && cachedAccountCodeTypes.lastUpdated > Date.now() - CACHE_DURATION_MS) {
        logger.info(
            `Not updating AccountCodeTypes[${context}] - we had a recent cached copy, lastUpdated[${cachedAccountCodeTypes.lastUpdated}]`
        );
        return false;
    }
    if (PENDING_UPDATES.get(context)) {
        logger.info(`Not updating AccountCodeTypes[${context}] - we're already processing an update`);
        return false;
    }
    return true;
};

export const getAccountCodeTypes = createAsyncThunk(
    'accountCodeTypes/get',
    async (context, { rejectWithValue }) => {
        PENDING_UPDATES.set(context, true);
        try {
            const accountCodeTypes = await fetchAccountCodeTypes(context);
            // We set the lastUpdated ourselves which we will refer to later to see if the cache is new enough
            // in our condition below
            accountCodeTypes.lastUpdated = Date.now();
            if (logger.isDebugEnabled()) {
                logger.debug(`Got new AccountCodeTypes[${context}]`, accountCodeTypes);
            }
            // This is the data that will be processed by the reducer as the "action.payload"
            return {
                context,
                accountCodeTypes,
            };
        } catch (error) {
            return rejectWithValue({
                context,
                error: error.message,
            });
        } finally {
            PENDING_UPDATES.set(context, false);
        }
    }, {
        // A condition determines if the dispatch of this action should even be executed
        // In this case we are determining if the account code types are already cached and we can save an API call
        condition: isAccountCodeTypesUpdateNeeded,
    }
);

const accountCodeTypesSlice = createSlice({
    name: 'accountCodeTypes',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getAccountCodeTypes.fulfilled, (state, action) => {
            const { context, accountCodeTypes } = action.payload;
            state[context] = accountCodeTypes;
        });
        builder.addCase(getAccountCodeTypes.rejected, (_state, action) => {
            const rejectionDetails = action.payload;
            logger.warn('Error while fetching account code types', rejectionDetails);
        });
    },
});

export default accountCodeTypesSlice.reducer;
