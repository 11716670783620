/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveBook } from '~/roles/ordering/store/slices/books';
import { getCart } from '~/roles/ordering/store/slices/orders';
import { getCurrentReviews } from '~/roles/ordering/store/slices/reviews';
import { getActiveSite, getSites } from '~/roles/ordering/store/slices/sites';
import { getCurrentUser } from '~common-store/slices/currentUser';
import getEnhancedComponentName from '~components/getEnhancedComponentName';

const UserContext = createContext(null);

const UserProvider = ({ children }) => {
    const [refreshCount, setRefreshCount] = useState(0);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.currentUser);

    const refresh = useCallback(() => {
        setRefreshCount((value) => value + 1);
    }, [setRefreshCount]);

    const context = useMemo(() => ({ user, refresh }), [user, refresh]);

    useEffect(() => {
        // Refresh everything that is in the toolbar options (book, review, cart)
        // All fetching of data that is done in ALL the tiles in userOrderingHeaderToolbarOptions should be done here again
        dispatch(getCurrentUser());
        dispatch(getActiveBook());
        dispatch(getActiveSite());
        dispatch(getSites());
        dispatch(getCart());
        dispatch(getCurrentReviews());
    }, [dispatch, refreshCount]);

    return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

/**
 * Gets the currently logged in user, including all the details returned by the CurrentUser API
 *
 * @returns {object | undefined} The current user (undefined if the current user has not been initialized)
 */
const useCurrentUser = () => {
    return useContext(UserContext).user;
};

/**
 * Gets a function that, when called, will cause the currentUser details to be re-fetched from the server
 *
 * @returns {function():void} The refresh function
 */
const useRefreshCurrentUser = () => {
    return useContext(UserContext).refresh;
};

const withUserData = (selector) => {
    return (WrappedComponent) => {
        const EnhancedComponent = (props) => {
            const userContext = useCurrentUser();
            return <WrappedComponent {...props} {...selector(userContext, props)} />;
        };
        EnhancedComponent.displayName = getEnhancedComponentName('WithUserData', WrappedComponent);
        return EnhancedComponent;
    };
};

/**
 * Checks to see if a user (as provided by useCurrentUser) has the specified role.
 *
 * @param {object | undefined} user - The user to check (expected to be in the form returned by the currentUser API - not appropriate for other user objects)
 * @param {string} role - The role to check (case sensitive - roles are expected to be all upper-case when returned by the currentUserAPI)
 * @returns {boolean} Does the provided user have the provided role?
 */
const doesUserHaveRole = (user, role) => {
    return user?.roles?.includes(role) || false;
};

/**
 * Checks to see if the user returned by useCurrentUser has the specified role.
 *
 * @param {string} role - The role to check (case sensitive - roles are expected to be all upper-case when returned by the currentUserAPI)
 * @returns {boolean} Does the current user have the provided role?
 */
const useDoesCurrentUserHaveRole = (role) => {
    const user = useCurrentUser();
    return doesUserHaveRole(user, role);
};

/**
 * Checks to see if the current user has the Approval role
 *
 * @returns {boolean} Is the current user an Approver?
 */
const useIsCurrentUserApprover = () => {
    return useDoesCurrentUserHaveRole('APPROVAL');
};

/**
 * Checks to see if the current user has the Ordering role
 *
 * @returns {boolean} Is the current user an Order Placer?
 */
const useIsCurrentUserOrderPlacer = () => {
    return useDoesCurrentUserHaveRole('ORDERING');
};

/**
 * Checks to see if the current user is a Catalog user (has the Workgroup role)
 *
 * @returns {boolean} Is the current user a Catalog?
 */
const useIsCurrentUserCatalog = () => {
    return useDoesCurrentUserHaveRole('WORKGROUP');
};

/**
 * @typedef AddressWithIsCatalog
 * @property {boolean} isCatalog Flag indicating if the user is a catalog or not
 * @property {!object} address The user's My Contact Information address
 */

/**
 * Checks to see if the current user is a Catalog user (has the Workgroup role), and returns that along with the user's My Contact Information
 *
 * @returns {AddressWithIsCatalog} The user's address and isCatalog flag
 */
const useCurrentUserAddressWithIsCatalog = () => {
    const user = useCurrentUser();
    const isCatalog = doesUserHaveRole(user, 'WORKGROUP');
    const address = user?.address || {};
    return { isCatalog, address };
};

export {
    UserProvider,
    useCurrentUser,
    useRefreshCurrentUser,
    withUserData,
    useIsCurrentUserApprover,
    useIsCurrentUserOrderPlacer,
    useIsCurrentUserCatalog,
    useCurrentUserAddressWithIsCatalog,
};
