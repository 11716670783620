/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { Fragment } from 'react';

import SimpleToken from './simpleToken';

/**
 * Line break tags
 * First line{br}Second line
 */
export default new SimpleToken(
    'br',
    (key) => {
        return <Fragment key={key}>{'\n'}</Fragment>;
    },
    '\n'
);
