/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

import { produce } from 'immer';

/**
 * Creates an object that is resistant to change.
 *
 * @param {?} initialValue - The starting value for the object.
 * @returns {[Function, Function]} A [get, update] array. get returns an immutable copy of the current value of the object, update modifies
 *                                the current value of the object, using immer style recipes
 */
export default (initialValue) => {
    let currentValue = null;

    const get = () => {
        return currentValue;
    };

    const update = (recipe) => {
        currentValue = produce(currentValue, recipe);
    };

    update(() => initialValue);

    return [get, update];
};
