/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2024 Rochester Software Associates (service@rocsoft.com)
 */

const getDisplayName = (Component) => {
    return Component.displayName || Component.name || 'Component';
};

export default (enhancedComponentPrefix, WrappedComponent) => {
    return `${enhancedComponentPrefix}-${getDisplayName(WrappedComponent)}`;
};
